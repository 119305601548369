import { OWAuthDataModel } from '~/models/OWAuthDataModel'
import React, { useEffect, useState } from 'react'
import { Maybe } from '~/app/types'

import { Global } from '~/global'

const { STATIC_PREFIX } = Global

const dontBind = new Set([
    'constructor',
    'render',
    'componentWillMount',
    'componentDidMount',
    'componentWillReceiveProps',
    'shouldComponentUpdate',
    'componentWillUpdate',
    'componentDidUpdate',
    'componentWillUnmount',
])

export class ComponentBase<T, S> extends React.Component<T, S> {
    mounted = false

    constructor(props) {
        super(props)
        const prototype = Object.getPrototypeOf(this)
        Object.getOwnPropertyNames(prototype)
            .filter(prop => !dontBind.has(prop))
            .forEach(prop => (this[prop] = this[prop].bind(this)))
        OWAuthDataModel.getInstance().subscribeToEmployeeUpdate(this)
        OWAuthDataModel.getInstance().subscribeToCompanyUpdate(this)

        if (props.setRef) {
            props.setRef(this)
        }
    }

    componentDidMount() {
        this.mounted = true
    }

    componentWillUnmount() {
        OWAuthDataModel.getInstance().unsubscribeToEmployeeUpdate(this)
        OWAuthDataModel.getInstance().unsubscribeToCompanyUpdate(this)
        this.mounted = false
    }
}

export const isRegionalCode = cp => cp >= 0x1f1e6 && cp <= 0x1f1ff

export const flagToSvg = (flag, countryName) => {
    /* Uses the assets from https://github.com/twitter/twemoji pulled 20210319 */
    if (!!flag && flag.length === 4) {
        const cp1 = flag.codePointAt(0)
        const cp2 = flag.codePointAt(2)
        if (isRegionalCode(cp1) && isRegionalCode(cp2)) {
            const filename = `${cp1.toString(16)}-${cp2.toString(16)}.svg`
            const full_filename = `${STATIC_PREFIX}orgwiki/img/flags/svg/${filename}`
            return <img src={full_filename} className={'svgFlag'} alt={countryName} />
        } else {
            return undefined
        }
    }
}

export const useMemoWithCleanup = <T,>(cb: () => [Maybe<T> | undefined, VoidFunction], deps) => {
    const [s, setS] = useState<Maybe<T>>()
    useEffect(() => {
        const vals = cb()
        setS(vals[0])
        return () => {
            vals[1]()
            setS(undefined)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps) // Intentionally doesn't recapture on cb changing because this is a "useMemo"
    // so snapshots the cp only when the deps change like a normal useMemo
    return s
}
