import React from "react";
import i18n from "i18next";

(function ($) {
    $.fn.durationOranges = function () {
        return this.each(function () {
            var durationOranges = $(this);
            if (durationOranges.data("orangesInitialized") !== true) {
                var isContractor = false;
                var isPrior = durationOranges.hasClass("prior");

                // Create the title text
                var title;
                const duration = durationOranges.data("humanFriendly");
                if (typeof duration == "undefined") title = null;
                else if (duration == "Contractor") {
                    title = i18n.t("stars_and_moons.tenure", { duration });
                    isContractor = true;
                } else {
                    title = i18n.t("stars_and_moons.tenure", { duration });
                }

                // Add the title to the durationOranges div
                if (title != null) {
                    durationOranges.attr("title", title);
                }

                // Also add the tooltip to the durationOranges div.
                // This will be enabled down below (only for mobile as of now)
                durationOranges.attr("data-toggle", "tooltip");

                if (isContractor) {
                    $(
                        '<span title="Contractor" class="contractor-orange ow-b-c-highlight">C</span>'
                    ).appendTo(durationOranges);
                } else {
                    // |years| is the decimal version of the years they have. Something like 3.9
                    // will be NaN for null or blank start dates
                    var years = parseFloat(durationOranges.data("years"));
                    // The number of full years. Will be NaN for null or blank start dates
                    var fullYears = Math.floor(years);
                    // Integer number of months that have passed in the remaining year
                    var months = durationOranges.data("months");

                    // For employees who work more than 5 years, we show stars to represent
                    // 5 year increments.  After 5 years, we don't show partial years (the hollow circle)
                    var oldTimerThreshold = 5.0;
                    var isOldTimer = years >= oldTimerThreshold;
                    var numOfStars = 0;
                    var iconClassNames = " duration-orange ";

                    if (isPrior) {
                        iconClassNames = iconClassNames + "prior";
                    } else {
                        iconClassNames = iconClassNames + "ow-b-c-highlight ";
                    }

                    // If this is an old timer, then we show a star for every 5 years
                    if (isOldTimer) {
                        // Calculate the the number of stars and add them
                        numOfStars = Math.floor(fullYears / oldTimerThreshold);
                        for (var i = 0; i < numOfStars; i++) {
                            $("<i/>", {
                                class: "icon-star" + iconClassNames
                            }).appendTo(durationOranges);
                        }
                    }

                    // Now we add solid circles.  Calculate the number of full years
                    var numOfSolidCircles = fullYears - numOfStars * oldTimerThreshold;
                    for (var i = 0; i < numOfSolidCircles; i++) {
                        $("<i/>", {
                            class: "icon-circle" + iconClassNames
                        }).appendTo(durationOranges);
                    }

                    // Now we add the hollow circle to represent partial year (only if not old timer)
                    // Add a partial circle if:
                    // it's their first year
                    // or if 1 <= years < 5 and months > 1
                    if ((fullYears === 0 || (fullYears > 0 && months >= 1)) && !isOldTimer) {
                        $("<i/>", {
                            class: "icon-circle-blank" + iconClassNames
                        }).appendTo(durationOranges);
                    }
                }

                // Set the flag
                durationOranges.data("orangesInitialized", true);
            }
        });
    };

    // Call this to the above function on all the duration-oranges
    $(document).on("OWApp.translationsReady", function () {
        $(".duration-oranges").durationOranges();
    });

    // For touch devices, we want to show a Bootstrap tooltip when the duration Oranges is pressed
    // First detect if this is a touch device
    var is_touch_device = "ontouchstart" in document.documentElement;
    if (is_touch_device) {
        // Initialize all the tooltips on the page.  Also, add a click function, so that
        // we toggle the tooltip on click/tap
        $(function () {
            $('[data-toggle="tooltip"]')
                .tooltip({ container: "body" })
                .click(function (e) {
                    $(this).tooltip("toggle");
                });
        });

        // Also, we want to hide the tooltip automatically after 2 seconds of it being shown
        $(".duration-oranges").on("shown.bs.tooltip", function () {
            setTimeout(function () {
                $(".duration-oranges").tooltip("hide");
            }, 2000);
        });
    }
})(jQuery);
