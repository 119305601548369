import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import { ServerApi } from '~/utils/ServerApi'
import * as Constants from '~/utils/Constants'
import { Modal } from 'react-bootstrap'

import { Global } from '~/global'

const { IS_AUTHENTICATED, OW_AUTH_DATA } = Global

type LanguagePickerModalComponentProps = {
    currentLanguage: string | undefined
    show: boolean
    hide: () => void
}

export const LanguagePickerModalComponent = ({ currentLanguage, show, hide }: LanguagePickerModalComponentProps) => {
    const { t } = useTranslation()
    const [language, setLanguage] = useState<string | undefined>(currentLanguage)
    const [saving, setSaving] = useState<boolean>(false)

    const onSave = () => {
        const postSave = () => {
            void i18n.changeLanguage(language)
            setSaving(false)
            hide()
        }

        // Check if we are authenticated (since you can change language in self-signup flow)
        if (IS_AUTHENTICATED && language) {
            setSaving(true)
            void ServerApi.updateDisplayLanguage(language).then(postSave)
        } else {
            postSave()
        }
    }

    const getLanguageRow = lang => {
        const selected = lang === language
        const { label, icon } = Constants.Languages[lang]
        return (
            <button
                key={lang}
                className={'btn btn-default btn-language ' + (selected ? ' active' : '')}
                onClick={() => setLanguage(lang)}
            >
                {icon} {label}
            </button>
        )
    }

    const getLanguageRows = () => {
        const enabledLanguages = OW_AUTH_DATA.enabled_languages ?? []
        return enabledLanguages.map(l => getLanguageRow(l.toLocaleLowerCase()))
    }

    const header = <p className="ow-s2-text-subheader modal-title">{t('language_picker.header')}</p>
    const body = (
        <React.Fragment>
            <div className="modal-body-multiCol languageSelectionModal">
                <div className="modal-body-leftCol">
                    <div className="avatarBase avatar-size-100pct ico-language-globe" />
                </div>
                <div className="modal-body-rightCol">{getLanguageRows()}</div>
            </div>
        </React.Fragment>
    )
    const footer = (
        <React.Fragment>
            <button className="btn btn-secondary" disabled={saving} onClick={hide}>
                {t('cancel')}
            </button>
            <button
                className={'btn btn-primary' + (saving ? ' spinning-button' : '')}
                disabled={saving}
                onClick={onSave}
            >
                {t('save')}
            </button>
        </React.Fragment>
    )

    return (
        <Modal show={show} onHide={hide}>
            <Modal.Header closeButton>{header}</Modal.Header>
            <Modal.Body>{body}</Modal.Body>
            <Modal.Footer>{footer}</Modal.Footer>
        </Modal>
    )
}
