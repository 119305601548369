import { Global } from '~/global'

/**
 * Add a value as the most recent instant search result
 * @param obj The object to add
 * @param type The type of the object
 * @param keyFunc A function that gets a unique id for objects of that type
 */
export const addToInstantSearch = <T>(obj: T, type: string, keyFunc: (object: T) => unknown) => {
    const existingResults = (Global.OW_AUTH_DATA.instant_search || {}).record_set || []
    const idx = existingResults.findIndex(res => res.type === type && keyFunc(res.value) === keyFunc(obj))
    if (idx !== -1) {
        existingResults.splice(idx, 1)
    }
    existingResults.unshift({ value: obj, type })
}
