import React, { KeyboardEvent, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import useDebounce from '~/react/common-components/hooks/useDebounce'

type Props<T> = {
    defaultQuery?: string

    //(result: Object, idx: Number) -> row: React.Node
    getRow?: (result: T, idx: number) => ReactNode

    //(query: String) -> results: Object[]
    getSyncSuggestions?: (query: string) => T[]

    //(query: String, results: Object[], async: Func, fail: Func) -> null
    getAsyncSuggestions: (
        query: string,
        results: T[],
        async: (results: T[], more: boolean) => void,
        fail: () => void
    ) => void

    //(query: String, result: Object, idx: Number, searchResults: Object[]) -> shouldKeep: string
    // shouldKeep represents the query string we want to show in the typeahead input after selection. If a user is in the middle of typing
    // and sees the result they expect, they could select the option and expect it to show up in the input box if it is a form.
    // An empty string indicates we want to clear the query input upon selection.
    onSelect?: (query: string, result: T, tdx: number, searchResults: T[]) => string

    focusFirst?: boolean
    initialFocus?: boolean
    wrapperClass?: string
    inputClass?: string

    //(result: Object) -> class: String
    liClass?: (result: T) => string

    placeholder?: string

    //(query: String, searchResults: Object[]) -> null
    onBlur?: (query: string, searchResults: T[]) => void

    //(query: String) -> result: Object
    noSelect?: (query?: string) => T

    noSpinner?: boolean
    noLines?: boolean
    keepFocus?: boolean
    onInputChange?: (a: string) => void
    disabled?: boolean
    alwaysShowSuggestions?: boolean
    onlyReturnSuggestions?: boolean
    doAsyncSearchOnEmpty?: boolean
    inputTestId?: string
}

export const OWTypeaheadComponent = <T,>({
    defaultQuery = '',
    getRow = () => null,
    getSyncSuggestions,
    getAsyncSuggestions,
    onSelect = () => {
        return ''
    },
    focusFirst = false,
    initialFocus = false,
    wrapperClass = '',
    inputClass = '',
    liClass,
    placeholder = '',
    onBlur,
    noSelect,
    noSpinner = false,
    noLines = false,
    keepFocus = false,
    onInputChange = undefined,
    disabled = false,
    alwaysShowSuggestions,
    onlyReturnSuggestions,
    doAsyncSearchOnEmpty = false,
    inputTestId,
}: Props<T>) => {
    const [query, setQuery] = useState<string>(defaultQuery)
    const [searchResults, setSearchResults] = useState<T[]>([])
    const [hasFocus, setHasFocus] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [selectedIdx, setSelectedIdx] = useState<number>(-1)
    const [suppressResults, setSuppressResults] = useState<boolean>(initialFocus)
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (initialFocus) inputRef.current?.focus()
    }, [initialFocus])

    const doAsyncSearch = useDebounce((search: string, currentResults: T[]) => {
        if (!search && !doAsyncSearchOnEmpty) return
        setQuery(search)
        const callback = (results: T[], more: boolean) => {
            if (search === inputRef.current?.value) {
                const currSelection = searchResults[selectedIdx]
                let newIdx = -1
                if (currSelection) {
                    newIdx = results.indexOf(currSelection)
                } else if (focusFirst && results) {
                    newIdx = 0
                }
                setLoading(Boolean(more))
                setSearchResults(results)
                setSelectedIdx(newIdx)
            }
        }
        const fail = () => setLoading(false)
        setLoading(true)
        getAsyncSuggestions(search, currentResults, callback, fail)
    }, 300)

    const doSyncSearch = useCallback(
        query => {
            // if query is empty string, we still want to do synchronous search
            // if query is null/undefined, skip
            if (query === null || query === undefined) return
            const syncResults = getSyncSuggestions ? getSyncSuggestions(query) : []
            setSearchResults(syncResults)
            setLoading(!!query)
            setSelectedIdx(focusFirst && syncResults.length > 0 ? 0 : -1)
            void doAsyncSearch(query, syncResults)
        },
        [doAsyncSearch, focusFirst, getSyncSuggestions]
    )

    const onQueryChange = useCallback(
        ({ target: { value } }) => {
            if (value) {
                setSuppressResults(false)
            }
            doSyncSearch(value)
            if (onInputChange) onInputChange(value)
        },
        [setSuppressResults, onInputChange, doSyncSearch]
    )

    useEffect(() => {
        if (inputRef.current) inputRef.current.value = defaultQuery
        onQueryChange({ target: { value: defaultQuery } })
    }, [defaultQuery, onQueryChange])

    const onFocus = () => {
        setHasFocus(true)
    }

    const handleBlur = () => {
        window.setTimeout(() => {
            if (document.activeElement !== inputRef.current) {
                setHasFocus(false)
                setSuppressResults(false)
                onBlur && onBlur(query, searchResults)
            }
        }, 300)
    }

    const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        const keyCode = e.keyCode || e.which
        const resultCount = searchResults.length

        if (keyCode === 27) {
            // Escape
            inputRef.current?.blur()
        } else if (keyCode === 40) {
            // Down
            if (selectedIdx + 1 < resultCount) {
                setSelectedIdx(selectedIdx + 1)
            } else {
                setSelectedIdx(-1)
            }
        } else if (keyCode === 38) {
            // Up
            if (selectedIdx - 1 >= -1) {
                setSelectedIdx(selectedIdx - 1)
            } else {
                setSelectedIdx(resultCount - 1)
            }
        } else if (keyCode === 10 || keyCode === 13) {
            // Enter
            if (selectedIdx !== -1 && selectedIdx < resultCount) {
                handleSelect(searchResults[selectedIdx], selectedIdx)
            } else if (noSelect) {
                const s = noSelect(inputRef.current?.value)
                if (s) {
                    handleSelect(s, -1)
                }
            }
        }
    }

    const handleSelect = (result: T, idx: number) => {
        // Notify parent of selection, and then reset if we aren't keeping the query
        const doSelect = () => {
            const keepQuery = onSelect(query, result, idx, searchResults)
            if (keepQuery) {
                setQuery(keepQuery)
                if (inputRef.current) inputRef.current.value = keepQuery
            } else {
                if (inputRef.current) inputRef.current.value = ''
                setQuery('')
                setLoading(false)
                setSearchResults([])
                setSelectedIdx(-1)
                doSyncSearch('')
            }
        }

        // If we aren't keeping focus, blur before handling selection
        if (keepFocus) {
            doSelect()
        } else {
            inputRef.current?.blur()
            setHasFocus(false)
            doSelect()
        }
    }

    const shouldShowSuggestions = () => {
        return (hasFocus && searchResults.length > 0 && (query || !suppressResults)) || alwaysShowSuggestions
    }

    const getSuggestions = (): JSX.Element[] => {
        if (!shouldShowSuggestions()) {
            return []
        }
        return searchResults.map((result, idx) => {
            const liClassVal = liClass && liClass(result)
            let last = false
            if (!noLines) {
                const next = searchResults[idx + 1]
                if (next) {
                    const nextLiClass = liClass && liClass(next)
                    last = liClassVal !== nextLiClass
                }
            }
            return (
                <li
                    className={
                        '' +
                        (idx === selectedIdx ? ' active' : '') +
                        (liClass ? ` ${liClassVal}` : '') +
                        (last ? ' last' : '')
                    }
                    onMouseEnter={() => setSelectedIdx(idx)}
                    key={`suggestion-${idx}`}
                    onClick={() => handleSelect(result, idx)}
                >
                    {getRow(result, idx)}
                </li>
            )
        })
    }

    const getLoadingRow = () => {
        return (
            hasFocus &&
            loading &&
            !noSpinner && (
                <li className="typeAheadItem no-highlight itemLoading">
                    <span className="typeAheadLoading spinning-button">&nbsp;</span>
                </li>
            )
        )
    }

    const getNoResultsRow = () => {
        const displayQuery = inputRef.current?.value
        return (
            hasFocus &&
            !loading &&
            Boolean(query) &&
            searchResults.length === 0 &&
            displayQuery === query && (
                <li className="typeAheadItem no-highlight noResults">
                    <span>No results found</span>
                </li>
            )
        )
    }

    const suggestions = getSuggestions()
    const loadingRow = getLoadingRow()
    const noResultsRow = getNoResultsRow()
    const active = suggestions.length > 0 || Boolean(loadingRow) || Boolean(noResultsRow) || alwaysShowSuggestions

    return (
        <div className={'typeAheadWrapper ' + wrapperClass + (disabled ? ' disabled' : '')}>
            <input
                className={'typeAheadInput ' + inputClass}
                defaultValue={defaultQuery}
                aria-label="..."
                onChange={onQueryChange}
                onFocus={onFocus}
                onBlur={handleBlur}
                disabled={disabled}
                onKeyUp={onKeyPress}
                ref={inputRef}
                placeholder={placeholder}
                autoComplete="new-password"
                data-testid={inputTestId}
            />
            {!onlyReturnSuggestions && (
                <ul className={'typeAheadDropdown' + (active ? ' active' : '')}>
                    {suggestions}
                    {loadingRow}
                    {noResultsRow}
                </ul>
            )}
        </div>
    )
}
