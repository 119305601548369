import { info } from '@pnotify/core'
import * as ReactI18next from 'react-i18next'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { isMobileScreenSize } from '~/utils/bootstrapUtils'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import { Global } from '~/global'
import Cookies from 'js-cookie'

declare global {
    interface Window {
        __coverage__?: unknown
    }
}

const notify = (title: string, hide = true) => {
    info({
        title,
        type: 'info',
        styling: 'alert',
        addClass: 'ow-standard-info-pnotify',
        sticker: false,
        hide,
        delay: 2300,
    })
}

const clearCache = () => {
    window.localStorage.clear()
    void navigator.clipboard.writeText(JSON.stringify(window.__coverage__)).then(() => notify('Cleared cache'))
    console.log('Cleared cache')
}
const toggleCoverage = () => {
    const hasCoverage = '__coverage__' in window
    if (hasCoverage) {
        Cookies.remove('coverage')
    } else {
        Cookies.set('coverage', '1', { expires: 7 })
    }
    notify(`${hasCoverage ? 'Disabling' : 'Enabling'} coverage...`, false)
    location.reload()
}
const copyCoverage = () => {
    void navigator.clipboard
        .writeText(JSON.stringify(window.__coverage__))
        .then(() => notify('Coverage data copied to clipboard'))
}
export const FooterComponent = () => {
    const { t } = useTranslation('common')
    const now = new Date()
    const appVersion = Global.APP_VERSION
    const supportContactEmail = Global.SUPPORT_CONTACT
    const isMobile = isMobileScreenSize()
    const hasCoverage = '__coverage__' in window

    return (
        <div id="ow-react-footer">
            <footer id="main-footer">
                <div id="main-footer-text" className="container-fluid">
                    <div className="row">
                        <div id="footer-copyright-text" className="col-xs-12 col-sm-12 col-md-6">
                            <span>
                                <ReactI18next.Trans i18nKey="footer.copyright" date={now}>
                                    Copyright ©2013—{{ date: now }}
                                    <a href="http://www.veeva.com/" target="_blank">
                                        Veeva
                                    </a>
                                    . All rights reserved.
                                </ReactI18next.Trans>
                            </span>
                        </div>
                        <div id="footer-links" className="col-xs-12 col-sm-12 col-md-6">
                            <span id="footer-pipe-separator">|</span>
                            <a href="/welcome/">{t('footer.about')}</a>
                            &nbsp;&nbsp;&nbsp;&nbsp;&middot;&nbsp;&nbsp;&nbsp;&nbsp;
                            <a href="https://theorgwiki.com/legal/" target="_blank">
                                {t('footer.legal')}
                            </a>
                            &nbsp;&nbsp;&nbsp;&nbsp;&middot;&nbsp;&nbsp;&nbsp;&nbsp;
                            <a href={`mailto:${supportContactEmail}`}>{t('footer.contact')}</a>
                        </div>
                        <br />
                    </div>
                </div>
                {!isMobile && (
                    <div id="basels-invisible-links">
                        <div className="hidden-detail">Version: {appVersion}</div>
                        <DropdownButton
                            align={'end'}
                            drop={'up'}
                            size="sm"
                            className="hidden-detail"
                            id={'hidden-menu'}
                            title={'Dev Tools'}
                        >
                            <Dropdown.Item eventKey="1" onSelect={clearCache}>
                                Clear Typeahead Cache
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="2" onSelect={toggleCoverage}>
                                {hasCoverage ? 'Disable' : 'Enable'} Coverage
                            </Dropdown.Item>
                            {hasCoverage && (
                                <Dropdown.Item eventKey="3" onSelect={copyCoverage}>
                                    Copy Coverage
                                </Dropdown.Item>
                            )}
                        </DropdownButton>
                    </div>
                )}
            </footer>
        </div>
    )
}
