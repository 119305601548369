import { relayStylePagination } from '@apollo/client/utilities'
import { AccrualPeriod } from '~/generated/graphql'

const accrualPeriodPagination = (fieldName = 'periods') => ({
    keyArgs: [],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    merge: function (existing: any, incoming: any, opts: any) {
        const mergedObj = opts.mergeObjects(existing, incoming)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const oldMatches = existing ? (opts.readField(fieldName, existing) ?? []) : []
        let newMatches = incoming ? (opts.readField(fieldName, incoming) ?? []) : []

        // If 'after' arg does not exist, can use new list entirely rather than merging
        if (opts.args?.after && oldMatches) {
            newMatches = newMatches.filter(
                (m: AccrualPeriod) => !oldMatches.some((oldM: AccrualPeriod) => oldM.date === m.date)
            )
        }
        const mergedMatches = opts.args?.after ? [...oldMatches, ...newMatches] : newMatches

        return { ...mergedObj, [fieldName]: mergedMatches }
    },
})

// Note: you must merge fields for the full object merge to have an "existing" object set.
export const typePolicies = {
    Query: {
        fields: {
            employees: relayStylePagination(['publicId', 'sort']),
            groups: relayStylePagination(['filters', 'sort']),
        },
    },
    HolidayCalendarType: {
        keyFields: ['wdRefId'],
    },
    EmployeeType: {
        fields: {
            timeOffEntries: relayStylePagination(['filters', 'sort']),
            accrualInfo: accrualPeriodPagination(),
        },
    },
    TimeOffEntryType: {
        keyFields: ['timeOffEntryId'],
    },
}
