import * as Constants from '~/utils/Constants'
import * as OWUtils from '~/utils/OWUtils'
import { ServerApi } from '~/utils/ServerApi'
import moment, { Moment } from 'moment'
import { Global } from '~/global'
import { Id } from '~/app/types'

const PAGE_SIZE = 15

export class NotificationsModel {
    private loaded: Moment | null
    notifications: any[]
    private listeners: any[]
    private loading: Promise<any> | null
    unseenCount: number
    unreadCount: number
    actionRequiredCount: number
    private next: number | null

    constructor() {
        this.loaded = null
        this.next = null
        this.notifications = []
        this.listeners = []
        this.loading = null
        this.unseenCount = Global.UNSEEN_NOTIFICATION_COUNT ?? 0
        this.unreadCount = Global.UNREAD_NOTIFICATION_COUNT ?? 0
        this.actionRequiredCount = Global.ACTION_REQUIRED_NOTIFICATION_COUNT ?? 0
        if (!OWUtils.hasFeature(Constants.Features.DISABLE_PRELOADING)) {
            void this.getNotifications()
        }
    }

    /**
     * Gets the global singleton
     * @returns {NotificationsModel}
     */
    static _notificationsInstance: NotificationsModel

    static getInstance() {
        if (!this._notificationsInstance) {
            this._notificationsInstance = new NotificationsModel()
        }
        return this._notificationsInstance
    }

    /**
     * Registers a callback to be called when the notification counts change
     * @param callback The callback
     * @returns A function to de-register the callback
     */
    onMutate(callback: (model: NotificationsModel) => void) {
        this.listeners.push(callback)
        return () => {
            const idx = this.listeners.indexOf(callback)
            if (idx !== -1) this.listeners.splice(idx, 1)
        }
    }

    _notifyListeners() {
        this.listeners.forEach(callback => {
            try {
                callback(this)
            } catch {
                // Swallow
            }
        })
    }

    /**
     * Gets the currently loaded notifications, and loads a page if needed
     * @return The notifications
     */
    getNotifications(): Promise<any[]> {
        if (OWUtils.hasFeature(Constants.Features.HIDE_NOTIFICATION_TRAY)) {
            return Promise.resolve([])
        }
        let prom: Promise<any> | null
        if (this.loaded) {
            prom = Promise.resolve(this.notifications)
            this._notifyListeners()
        } else if (this.loading) {
            prom = this.loading
        } else {
            prom = ServerApi.getNotifications(PAGE_SIZE, 1).then(
                ({ results, next, unseen_count, unread_count, action_required_count }) => {
                    Object.assign(this, {
                        next: next ? 2 : null,
                        loading: null,
                        loaded: moment(),
                        unseenCount: unseen_count,
                        unreadCount: unread_count,
                        actionRequiredCount: action_required_count,
                        notifications: results,
                    })
                    this._notifyListeners()
                    return this.notifications
                }
            )
            this.loading = prom
        }
        return prom
    }

    /**
     * Loads the next page of notifications, and returns the full list of loaded notifications
     */
    getNextNotifications(): Promise<object[]> {
        if (!this.loaded || !this.next) {
            return this.getNotifications()
        } else {
            if (this.loading) {
                return this.loading
            } else {
                if (this.next) {
                    this.loading = ServerApi.getNotifications(PAGE_SIZE, this.next).then(
                        ({ results, next, unseen_count, unread_count, action_required_count }) => {
                            this.next = next ? (this.next || 0) + 1 : null
                            this.unseenCount = unseen_count
                            this.unreadCount = unread_count
                            this.loading = null
                            this.loaded = moment()
                            this.actionRequiredCount = action_required_count
                            this.notifications = this.notifications.concat(results)
                            this._notifyListeners()
                            return this.notifications
                        }
                    )
                    return this.loading
                } else {
                    return Promise.resolve(this.notifications)
                }
            }
        }
    }

    /**
     * Marks all notifications as read
     * @returns {Promise} The result promise
     */
    clearAllNotifications(): Promise<void> {
        const prom = this.loading || Promise.resolve(null)
        return prom.then(() =>
            ServerApi.clearAllNotifications().then(({ unseen_count, unread_count, action_required_count }) => {
                this.notifications = this.notifications.map(n =>
                    Object.assign(n, {
                        is_seen: true,
                        is_active: false,
                    })
                )
                this.unseenCount = unseen_count
                this.unreadCount = unread_count
                this.actionRequiredCount = action_required_count
                this._notifyListeners()
            })
        )
    }

    /**
     * Marks notifications as read
     * @param ids The ids of the notifications to clear
     * @returns The result promise
     */
    clearNotifications(ids: Id[]) {
        const prom = this.loading || Promise.resolve(null)
        return prom.then(() =>
            ServerApi.clearNotifications((ids || []).join(',')).then(
                ({ unseen_count, unread_count, action_required_count }) => {
                    this.notifications = this.notifications.map(n => {
                        if (ids.includes(n.id)) {
                            n.is_active = false
                            n.is_seen = true
                        }
                        return n
                    })
                    this.unseenCount = unseen_count
                    this.unreadCount = unread_count
                    this.actionRequiredCount = action_required_count
                    this._notifyListeners()
                }
            )
        )
    }

    /**
     * Marks all notifications as seen
     * @returns The result promise
     */
    seeAllNotifications() {
        return ServerApi.seeNotifications().then(() => {
            this.notifications = this.notifications.map(n => Object.assign(n, { is_seen: true }))
            this.unseenCount = 0
            this._notifyListeners()
        })
    }

    /**
     * Refresh the notification counts
     */
    refreshCounts() {
        const prom =
            this.loading ||
            ServerApi.getNotificationCounts().then(({ unseen_count, unread_count, action_required_count }) => {
                if (
                    this.unseenCount !== unseen_count ||
                    this.actionRequiredCount !== action_required_count ||
                    this.unreadCount !== unread_count
                ) {
                    // Force refresh
                    this.loaded = null
                    this.notifications = []
                    void this.getNotifications()
                }
                this.unseenCount = unseen_count
                this.unreadCount = unread_count
                this.actionRequiredCount = action_required_count
                this._notifyListeners()
            })
        return prom.then(() => ({
            unseenCount: this.unseenCount,
            unreadCount: this.unreadCount,
            actionRequiredCount: this.actionRequiredCount,
        }))
    }
}
