import * as OWUtils from '~/utils/OWUtils'
import moment from 'moment'
import { OWAuthDataModel } from '~/models/OWAuthDataModel'
import { TFunction } from 'i18next'
import { Global } from '~/global'
import { SearchEmployee } from '~/react/search/SearchTypes'
import { Employee } from '~/app/types'

const { IS_ACTIVE_ADMIN } = Global

export const compareNames = (a, b) => {
    const nameA = a.full_name.toUpperCase() // ignore upper and lowercase
    const nameB = b.full_name.toUpperCase() // ignore upper and lowercase
    if (nameA < nameB) {
        return -1
    }
    if (nameA > nameB) {
        return 1
    }

    // names must be equal
    return 0
}

export const mediumImageForEmployee = employee => {
    return (
        (employee && employee.profile_photo_thumbnail && `/medium_images/${employee.profile_photo_base}`) ||
        defaultProfilePhoto()
    )
}

export const imageForEmployee = employee => {
    if (employee.profile_photo) {
        return employee.profile_photo
    } else if (employee.profile_photo_base) {
        return `/images/${employee.profile_photo_base}`
    } else if (employee.profile_photo_thumbnail) {
        return employee.profile_photo_thumbnail
    } else {
        return defaultProfilePhoto()
    }
}

/**
 * Compare the logical distance of two employees.
 */
export const compareDistance = (a, b) => {
    return a.proximity - b.proximity
}

/**
 * Compute the logical distance between an employee and the current user.
 * The algorithm considers the relative position of the employees in the
 * organization structure as well as their physical location.
 */

export const computeProximity = (e: Employee, u: SearchEmployee) => {
    const le = e.lineage?.slice(0, -1).split('-').map(Number) ?? []
    const lu = u.lineage?.slice(0, -1).split('-').map(Number) ?? []

    // Increase the distance if the employees are not in the same location
    const locationBoost = e.location_id === u.location_id ? 1 : 1.3

    // Determine the index of their closest common manager
    let i = 0
    const end = Math.min(le.length, lu.length)
    while (i < end && le[i] === lu[i]) {
        i++
    }

    // If there is no common ancestor, make the distance large and apply the location boost.
    if (!i) {
        return 1000 * locationBoost
    }
    // Do the employee and the user report to the same manager?
    if (le.at(-2) === lu.at(-2)) {
        return 0
    }
    // Is there a dotted-line relationship?
    const eId = le.at(-1)
    if (eId && ((u.dotted_line_report_ids ?? []).includes(eId) || (u.dotted_line_manager_ids ?? []).includes(eId))) {
        return 1
    }

    // Calculate distance where:
    // nUp - number of upward movements
    // nDown - number of downward movements
    // nAcross - number of across movements
    const nUp = lu.length - i
    const nDown = le.length - i
    const nAcross = nUp > 1 && nDown > 1 ? 1 : 0

    // Weights for each component
    const dDown = 2
    const dAcross = 1.25
    const dUp = 1

    const distance = dDown * nDown + dAcross * nAcross + dUp * nUp
    return distance * locationBoost
}

/**
 * Sort employees in ascending org distance and case-insensitive name order.
 *
 * @param a {Employee} first employee
 * @param b {Employee} second employee
 * @returns {number} -1, 0, or 1
 */
export const employeeSorter = (a, b) => {
    return compareDistance(a, b) || compareNames(a, b)
}
/**
 * Add the proximity property to a list of employees based on the given user.
 *
 * @param user {SearchEmployee} the current user
 * @param employees {Employee[]} a list of employees
 * @returns {Employee[]} the updated list of employees
 */
export type EmployeeWithProximity = Employee & { proximity?: number }
export const addProximity = (user: SearchEmployee, employees: EmployeeWithProximity[]) => {
    employees.forEach(function (e) {
        if (e.proximity === undefined) {
            e.proximity = computeProximity(e, user)
        }
    })
    return employees
}

/**
 * Determines if a given employee is the same as the logged-in-user
 * @param {Employee} employee The employee to check
 * @returns {boolean} True iff the employee is the same as the logged-in-user
 */
export const isSelf = employee => {
    return OWAuthDataModel.getAuthPublicId() === employee.public_id
}

/**
 * Determines if the context user can edit a given employee
 * @param {Employee} employee The employee to edit
 * @returns {boolean}
 */
export const canEditEmployee = employee => {
    return IS_ACTIVE_ADMIN || OWUtils.hasWikiProfileEdits() || isSelf(employee)
}

/**
 * Gets the page title for the page
 * @param {string} name The full name of the employee
 * @param {Boolean} [edit] If true, get the title for the edit page
 * @returns {string} The page title
 */
export const getPageTitle = ({ name, edit }) => {
    const prefix = edit ? 'Edit Employee ' : ''
    return `${prefix}${name} | OrgWiki`
}

/**
 * Gets the defailt profile image
 * @returns {string} the image path
 */
export const defaultProfilePhoto = () => {
    return OWUtils.staticPath('orgwiki/img/default_profile_photo.png')
}

/**
 * Gets the time since text for a date
 * @param date date to get the time since text from
 * @param profileUpdate true, return "Last Updated" version of string otherwise just return time since text
 * @param t the translation object
 * @returns A dictionary of lastUpdatedText and isOld
 */
export const getLastUpdatedText = (date: Date | undefined, profileUpdate: boolean, t: TFunction) => {
    if (!date) return { lastUpdatedText: '', isOld: false }
    const now = moment()
    const m = moment(date)
    const secondDiff = now.diff(m, 'seconds')
    const minuteDiff = now.diff(m, 'minutes')
    const hourDiff = now.diff(m, 'hours')
    const dayDiff = now.diff(m, 'days')
    const minuteDisplay = Math.round(now.diff(m, 'minutes', true))
    const hourDisplay = Math.round(now.diff(m, 'hours', true))
    const dayDisplay = Math.round(now.diff(m, 'days', true))
    const weekDisplay = Math.round(now.diff(m, 'weeks', true))
    const monthDisplay = Math.round(now.diff(m, 'months', true))
    const yearDisplay = Math.round(now.diff(m, 'years', true))
    let tstring: string
    if (secondDiff <= 89) {
        tstring = profileUpdate ? t('profile_page.updated_one_minute_ago') : t('profile_page.updated_one_minute_ago')
    } else if (secondDiff >= 90 && minuteDiff <= 44) {
        tstring = profileUpdate
            ? t('profile_page.updated_count_minutes_ago', { minuteDisplay })
            : t('profile_page.count_minutes_ago', { minuteDisplay })
    } else if (minuteDiff >= 45 && minuteDiff <= 89) {
        tstring = profileUpdate ? t('profile_page.updated_an_hour_ago') : t('profile_page.an_hour_ago')
    } else if (minuteDiff >= 90 && hourDiff <= 21) {
        tstring = t('profile_page.count_hours_ago', { hourDisplay })
    } else if (hourDiff >= 22 && hourDiff <= 35) {
        tstring = profileUpdate ? t('profile_page.updated_a_day_ago') : t('profile_page.a_day_ago')
    } else if (hourDiff >= 36 && dayDiff <= 10) {
        tstring = profileUpdate
            ? t('profile_page.updated_count_days_ago', { dayDisplay })
            : t('profile_page.count_days_ago', { dayDisplay })
    } else if (dayDiff >= 11 && dayDiff <= 73) {
        tstring = profileUpdate
            ? t('profile_page.count_weeks_ago', { weekDisplay })
            : t('profile_page.count_weeks_ago', { weekDisplay })
    } else if (dayDiff >= 74 && dayDiff <= 714) {
        tstring = profileUpdate
            ? t('profile_page.count_months_ago', { monthDisplay })
            : t('profile_page.count_months_ago', { monthDisplay })
    } else if (dayDiff >= 715) {
        tstring = profileUpdate
            ? t('profile_page.count_years_ago', { yearDisplay })
            : t('profile_page.count_years_ago', { yearDisplay })
    } else {
        tstring = m.from(now)
    }
    return { lastUpdatedText: tstring, isOld: monthDisplay >= 12 }
}
