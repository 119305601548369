import { useEffect } from 'react'

export const useTitle = (title: string, skip = false) => {
    useEffect(() => {
        if (skip) {
            return
        }
        title && (document.title = title)
    }, [title, skip])
}
