import { AuthEmployee, Company } from '~/app/types'
import { GetBrandingQuery } from '~/generated/graphql'

export type GlobalType = {
    // Data imported from the initial page load
    IS_ES_PARTNER: boolean
    FILE_UPLOADS_MAX_SIZE_IN_MEGABYTES: number
    CAN_ACCESS_TRANSFER_PORTAL: boolean
    HIDE_NAVBAR_SEARCH: boolean
    HIDE_TOOLS: boolean
    TAGS_ENABLED: boolean
    IS_ADMIN: boolean
    ONLY_LOGOUT: boolean
    CAN_CREATE_LOCAL_COMMUNITIES: boolean

    PROFILE_CELEBRATIONS_ENABLED: boolean
    IS_BOARD_OF_DIRECTORS: boolean
    GOOGLE_ANALYTICS_SITE_NAME: string
    GOOGLE_ANALYTICS_CODE: string
    MIXPANEL_API_TOKEN: string
    OW_AUTH_DATA: {
        employee?: AuthEmployee
        company?: Company
        features?: string[]
        enabled_languages?: string[]
        language?: string
        enabled_language?: string
        localized_text?: string
        get_branding?: { data: GetBrandingQuery }
        inactive_office_count?: number
        instant_search?: { record_set: any[] }
    }
    STATIC_PREFIX: any
    IS_ACTIVE_ADMIN: any
    IS_GUEST: any
    IS_GHOST: any
    CURRENT_EMPLOYEE_PUBLIC_ID: any
    COMPANY_ID: any
    COMPANY_GUID: string
    MAIN_URL: any
    CAN_SPA: any
    ROOT_PUBLIC_ID: any
    ROOT_FULL_NAME: any
    SPREADSHEET_IMPORT_TEMPLATE: string
    EMPLOYEE_SIGNUP_DATA: any
    TZ_OFFSET: number
    TZ_NAME: string

    EXTERNAL_NAME: string
    APP_VERSION: string
    SUPPORT_CONTACT: string
    IS_AUTHENTICATED: boolean
    IS_NATIVE_MOBILE: boolean
    USER_INFO: string

    Wistia: { api: (id: string) => { play: VoidFunction } }
    API_URL_EMPLOYEES_LIST: string
    USER_TIMEZONE_OFFSET: string
    DLIST_PROVIDER: string
    UNSEEN_NOTIFICATION_COUNT?: number
    UNREAD_NOTIFICATION_COUNT?: number
    ACTION_REQUIRED_NOTIFICATION_COUNT?: number
    ESRI_API_KEY: string
    RELOAD_PAGE_ON_NAV?: boolean
    HIDE_CONFIG_BANNER: boolean
    SHOW_ONBOARDING_BANNER: boolean
    ONBOARDING_STEP: string
    LINK_TO_TRANSFER_POLICY: string
    GUEST_ACTION_DENIED_MSG: string
}

declare const global: GlobalType
export const Global = global
