import Cookies from 'js-cookie'

/**
 * Created by baselqumsiyeh on 1/14/17.
 */

/**
 * Given AJAX request data that is about to be sent to the server, check to see if there is a
 * method override data parameter (named "_method").  If so, return the value of that.  If not, return null
 * @param xhrSettings The settings object on the XHR request.
 * @returns String or null
 */
export function extractMethodOverride(xhrSettings) {
    let overrideMethod: string | undefined
    const data = xhrSettings.data

    if (typeof data == 'string') {
        // If the data is a string, that means its concatenated key value pairs by &.  Something like
        // "field1=val1&field2=val2&field3=val3"

        // Split the data by & first
        const dataArr = data.split('&')

        $.each(dataArr, function (index, value) {
            // Loop through each one, checking for the method key
            if (value.startsWith('_method=')) {
                // If found, take the value of the pair by looking to the right of the equal sign
                const methodArr = value.split('=')
                overrideMethod = methodArr[1]
            }
        })
    } else if (data instanceof FormData) {
        // Check if the browser supports the .get() method on FormData
        // See https://developer.mozilla.org/en-US/docs/Web/API/FormData#Browser_compatibility for support
        if (data.get == null) {
            // The FormData.get() is not supported in this browser, so we fall back to a custom solution
            // `nonFormData` was added by us to the jquery.form.js library to allow us to get the original data
            // on the form (not using the FormData class)
            const originalFormData = xhrSettings.originalFormData
            for (const formField of originalFormData) {
                // Loop through the original form data and look for one called _method
                if (originalFormData[formField].name == '_method') {
                    // Found so return it
                    return originalFormData[formField].value
                }
            }
            // If we get here that means there isn't a _method form field
            return
        } else {
            // If its a FormData object, we can just use the .get() method to look for the key
            overrideMethod = data.get('_method') as string
        }
    }

    // Return what we have
    return overrideMethod
}

export function getStandardUploadRequestHeaders() {
    return {
        'X-CSRFToken': Cookies.get('csrftoken'),
        'X-HTTP-Method-Override': 'PATCH',
    }
}
