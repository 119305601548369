import * as Constants from '~/utils/Constants'
import * as OWUtils from '~/utils/OWUtils'
import { isNativeMobile } from '~/utils/urlUtils'
import { OWApp } from '~/app/OWApp'

import { Global } from '~/global'

const { HIDE_CONFIG_BANNER, SHOW_ONBOARDING_BANNER } = Global

export const NavbarUtils = {
    shouldShowConfigBanner() {
        return (
            OWUtils.hasFeature(Constants.Features.PRELAUNCH_MODE) &&
            !HIDE_CONFIG_BANNER &&
            !['/welcome/', '/okta/', '/o/authorize/'].includes(location.pathname) &&
            !location.pathname.includes('/my-profile/') &&
            !isNativeMobile()
        )
    },

    shouldShowModerationBanner(datetime: string | undefined) {
        const app = OWApp.getInstance()

        return (
            app.authData.company &&
            datetime &&
            !HIDE_CONFIG_BANNER &&
            !['/welcome/', '/okta/', '/o/authorize/'].includes(location.pathname) &&
            app.authData.employee &&
            app.authData.employee.is_admin &&
            !isNativeMobile()
        )
    },

    shouldShowOnboardingBanner() {
        return SHOW_ONBOARDING_BANNER && !location.pathname.includes('/my-profile/')
    },
}
