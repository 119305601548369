import { PlaceManager, urlForAdminCompany } from '~/utils/PlaceManager'
import * as OWUtils from '~/utils/OWUtils'
import * as ReactI18next from 'react-i18next'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { ADMIN_COMPANY_PAGE_TABS } from '~/react/tabs'
import { Employee } from '~/app/types'

export const PreLaunchModeBannerComponent = ({ authEmployee }: { authEmployee: Employee }) => {
    const canDisable = authEmployee && authEmployee.is_admin
    const canDisableNow = canDisable && authEmployee.admin_mode_enabled
    const { t } = useTranslation()
    const prelaunchHref = urlForAdminCompany({
        v: ADMIN_COMPANY_PAGE_TABS.prelaunch,
    })
    const prelaunchClick = OWUtils.onFilteredLeftClick(() =>
        PlaceManager.getInstance().goToAdminCompany({ v: ADMIN_COMPANY_PAGE_TABS.prelaunch }, true)
    )
    return (
        <div className="navbar-banner configuration-mode-warning">
            <div className="container-fluid">
                {canDisableNow ? (
                    <p>
                        {t('banners.orgwiki_prelaunch_mode')}&nbsp;
                        <ReactI18next.Trans i18nKey={'banners.launch_orgwiki'}>
                            0
                            <a href={prelaunchHref} onClick={prelaunchClick}>
                                {' '}
                                1{' '}
                            </a>{' '}
                            2
                        </ReactI18next.Trans>
                    </p>
                ) : (
                    <p>{t('banners.orgwiki_not_rolled_out')}</p>
                )}
            </div>
        </div>
    )
}
