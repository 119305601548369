import React, { useState } from 'react'
import { EmployeesModel } from '~/models/EmployeesModel'
import { ServerApi } from '~/utils/ServerApi'
import { isMobileScreenSize } from '~/utils/bootstrapUtils'
import * as OWUtils from '~/utils/OWUtils'
import { EmployeeBadgeMetricsWithoutDurationOrangesDependency } from '~/react/search/EmployeeBadgeMetricsWithoutDurationOrangesDependency'
import * as GroupUtils from '~/utils/GroupUtils'
import * as ReactI18next from 'react-i18next'
import { useTranslation } from 'react-i18next'
import lscache from 'lscache'
import * as TypeaheadUtils from '~/utils/TypeaheadUtils'
import { PlaceManager } from '~/utils/PlaceManager'
import * as Constants from '~/utils/Constants'
import { getQueryVariable } from '~/utils/urlUtils'
import { OWTypeaheadComponent } from '~/react/common-components/OWTypeaheadComponent'
import { mixpanelTrack } from '~/utils/MixpanelUtils'
import {
    CompanyProduct,
    CompanyRole,
    ConferenceRoom,
    Employee,
    Group,
    InMemoriam,
    LocationType,
    Skill,
} from '~/app/types'
import { Global } from '~/global'

type Props = {
    maxEmployees?: number
    maxTotal?: number
    groupsEnabled?: boolean
    tagsEnabled?: boolean
    isSearch?: boolean
    q?: string
    autofocus?: boolean
}

const resultTypes = {
    employee: 'Employee',
    group: 'Group',
    skill: 'Skill',
    office: 'Location',
    conferenceRoom: 'ConferenceRoom',
    role: 'Role',
    product: 'Product',
    inMemoriam: 'InMemoriam',
    groupPage: 'GroupPage',
    advancedSearch: 'AdvancedSearch',
}

const getMixpanelInfo = ({ type, value }) => {
    if (type === resultTypes.employee) {
        return {
            selectionType: 'Employee',
            selectionValue: value.public_id,
        }
    } else if (type === resultTypes.office) {
        return { selectionType: 'Location', selectionValue: value.slug }
    } else if (type === resultTypes.conferenceRoom) {
        return {
            selectionType: 'ConferenceRoom',
            selectionValue: `${value.location_slug}:${value.slug}`,
        }
    } else if (type === resultTypes.group) {
        return { selectionType: 'Group', selectionValue: value.slug_name }
    } else if (type === resultTypes.skill) {
        return { selectionType: 'Skill', selectionValue: value.slug_name }
    } else if (type === resultTypes.role) {
        return { selectionType: 'role', selectionValue: value.name }
    } else if (type === resultTypes.product) {
        return { selectionType: 'Product', selectionValue: value.name }
    } else if (type === resultTypes.inMemoriam) {
        return {
            selectionType: 'InMemoriam',
            selectionValue: value.full_name,
        }
    } else {
        return {}
    }
}

const trackMixpanel = (query, { type, value }, idx, results) => {
    const { selectionValue, selectionType } = getMixpanelInfo({
        type,
        value,
    })
    const employeeCount = results.filter(({ type }) => type === resultTypes.employee).length
    const groupCount = results.filter(({ type }) => type === resultTypes.group).length
    const skillCount = results.filter(({ type }) => type === resultTypes.skill).length
    const officeCount = results.filter(({ type }) => type === resultTypes.office).length
    const conferenceRoomCount = results.filter(({ type }) => type === resultTypes.conferenceRoom).length
    if (selectionType && selectionValue) {
        if (query) {
            return mixpanelTrack('Instant Search - Recent Search Resused', {
                'Search Type': selectionType === 'Query' ? 'Advanced' : selectionType,
            })
        } else {
            return mixpanelTrack('Instant Search - Item Selected', {
                'Query String': query,
                'Employee Hits': employeeCount,
                'Group Hits': groupCount,
                'Skill Hits': skillCount,
                'Office Hits': officeCount,
                'Conference Room Hits': conferenceRoomCount,
                'Selected Object': selectionValue,
                'Selection Type': selectionType,
                'Selection Rank': idx + 1,
            })
        }
    } else {
        return $.Deferred().resolve(null)
    }
}

const getLiClass = ({ type, value: _, recent = false }) => {
    // Recents have no class
    // Everything else uses self class except conference rooms, which alias as offices
    return recent ? null : type === resultTypes.conferenceRoom ? resultTypes.office : type
}

const { OW_AUTH_DATA } = Global

export const NavbarTypeahead = ({
    maxEmployees = 6,
    maxTotal = 12,
    groupsEnabled = false,
    tagsEnabled = false,
    isSearch = false,
    q = '',
    autofocus = false,
}: Props) => {
    const model = EmployeesModel.getInstance()
    const { t } = useTranslation()
    const [lastInput, setLastInput] = useState<string>()

    const getExtraRow = query => {
        let extraRow
        if (query && query.toLocaleLowerCase() === 'groups' && groupsEnabled) {
            extraRow = { type: resultTypes.groupPage }
        } else {
            extraRow = {
                type: resultTypes.advancedSearch,
                value: query,
            }
        }
        return extraRow
    }

    const getAsyncResults = (query, searchResults, async, fail) => {
        if (!query) {
            async(searchResults)
            return
        }
        const extraRow = searchResults.find(({ type }) => type !== resultTypes.employee)
        let acc = searchResults.filter(({ type }) => type === resultTypes.employee)
        const currentEmployeePublicIds = Object.fromEntries(acc.map(({ value: { public_id } }) => [public_id, true]))
        const employeeCount = Math.min(acc.length, maxEmployees)
        const employeeProm = new Promise<Employee[]>(resolve => {
            if (acc.length < maxTotal) {
                ServerApi.searchEmployees(maxTotal, 1, query).then(
                    results => resolve(results.filter(({ public_id }) => !currentEmployeePublicIds[public_id])),
                    () => resolve([])
                )
            } else {
                resolve([])
            }
        })

        const officeProm = new Promise<LocationType[]>(resolve => {
            ServerApi.getLocations(['-people_count', 'name'], maxTotal - employeeCount, 1, query).then(
                ({ results }) => resolve(results),
                () => resolve([])
            )
        })

        const conferenceProm = new Promise<ConferenceRoom[]>(resolve => {
            if (isMobileScreenSize()) {
                // Suppress conference rooms on mobile web
                resolve([])
            } else {
                ServerApi.getConferenceRooms(['name'], maxTotal - employeeCount, 1, query).then(
                    ({ results }) => resolve(results),
                    () => resolve([])
                )
            }
        })

        const groupProm = new Promise<Group[]>(resolve => {
            if (groupsEnabled) {
                ServerApi.getGroups(['-type', 'name'], maxTotal - employeeCount, 1, query).then(
                    ({ results }) => resolve(results),
                    () => resolve([])
                )
            } else {
                resolve([])
            }
        })

        const tagProm = new Promise<Skill[]>(resolve => {
            if (tagsEnabled) {
                ServerApi.getSkills(['-num_active', 'name'], query, maxTotal - employeeCount, 1, undefined, true).then(
                    ({ results }) => resolve(results),
                    () => resolve([])
                )
            } else {
                resolve([])
            }
        })

        const areaEnabled = OWUtils.isAreaEnabled()
        const roleProm = new Promise<CompanyRole[]>(resolve => {
            if (areaEnabled) {
                ServerApi.getRoles(query).then(
                    results => resolve(results),
                    () => resolve([])
                )
            } else {
                resolve([])
            }
        })
        const productProm = new Promise<CompanyProduct[]>(resolve => {
            if (areaEnabled) {
                ServerApi.getProducts(query).then(
                    results => resolve(results),
                    () => resolve([])
                )
            } else {
                resolve([])
            }
        })

        const inMemoriamProm = new Promise<InMemoriam[]>(resolve => {
            ServerApi.getInMemoriams(query).then(
                ({ results }) => resolve(results),
                () => resolve([])
            )
        })

        const employeesDone = employeeProm.then(
            employeeResults => {
                // First take employees up to capacity
                if (employeeCount < maxEmployees) {
                    const employeeSlots = maxEmployees - employeeCount
                    const newEmployees = employeeResults.slice(0, employeeSlots).map(e => ({
                        type: resultTypes.employee,
                        value: e,
                    }))
                    acc = acc.concat(newEmployees).concat([extraRow])
                    async(acc, true)
                    return maxTotal - employeeCount - newEmployees.length
                } else {
                    acc = acc.concat([extraRow])
                    async(acc, true)
                    return maxTotal - employeeCount
                }
            },
            () => fail()
        )

        Promise.all([
            employeesDone,
            officeProm,
            conferenceProm,
            groupProm,
            tagProm,
            roleProm,
            productProm,
            inMemoriamProm,
        ]).then(
            ([
                openSlots,
                officeResults,
                conferenceResults,
                groupResults,
                tagResults,
                roleResults,
                productResults,
                inMemoriamResults,
            ]) => {
                let officeSlots = 0,
                    conferenceSlots = 0,
                    groupSlots = 0,
                    tagSlots = 0,
                    roleSlots = 0,
                    productSlots = 0,
                    inMemoriamSlots = 0
                // Round robin slots
                while (
                    openSlots > 0 &&
                    (officeResults.length > officeSlots ||
                        conferenceResults.length > conferenceSlots ||
                        groupResults.length > groupSlots ||
                        tagResults.length > tagSlots ||
                        roleResults.length > roleSlots ||
                        productResults.length > productSlots ||
                        inMemoriamResults.length > inMemoriamSlots)
                ) {
                    if (roleResults.length > roleSlots) {
                        roleSlots++
                        openSlots--
                        if (openSlots <= 0) {
                            break
                        }
                    }
                    if (productResults.length > productSlots) {
                        productSlots++
                        openSlots--
                        if (openSlots <= 0) {
                            break
                        }
                    }
                    if (officeResults.length > officeSlots) {
                        officeSlots++
                        openSlots--
                        if (openSlots <= 0) {
                            break
                        }
                    } else if (conferenceResults.length > conferenceSlots) {
                        conferenceSlots++
                        openSlots--
                        if (openSlots <= 0) {
                            break
                        }
                    }
                    if (groupResults.length > groupSlots) {
                        groupSlots++
                        openSlots--
                        if (openSlots <= 0) {
                            break
                        }
                    }
                    if (tagResults.length > tagSlots) {
                        tagSlots++
                        openSlots--
                        if (openSlots <= 0) {
                            break
                        }
                    }
                    if (inMemoriamResults.length > inMemoriamSlots) {
                        inMemoriamSlots++
                        openSlots--
                        if (openSlots <= 0) {
                            break
                        }
                    }
                }
                acc = acc
                    .concat(
                        roleResults
                            .map(s => ({
                                type: resultTypes.role,
                                value: s,
                            }))
                            .slice(0, roleSlots)
                    )
                    .concat(
                        productResults
                            .map(s => ({
                                type: resultTypes.product,
                                value: s,
                            }))
                            .slice(0, productSlots)
                    )
                    .concat(
                        officeResults
                            .map(o => ({
                                type: resultTypes.office,
                                value: o,
                            }))
                            .slice(0, officeSlots)
                    )
                    .concat(
                        conferenceResults
                            .map(r => ({
                                type: resultTypes.conferenceRoom,
                                value: r,
                            }))
                            .slice(0, conferenceSlots)
                    )
                    .concat(
                        groupResults
                            .map(g => ({
                                type: resultTypes.group,
                                value: g,
                            }))
                            .slice(0, groupSlots)
                    )
                    .concat(
                        tagResults
                            .map(s => ({
                                type: resultTypes.skill,
                                value: s,
                            }))
                            .slice(0, tagSlots)
                    )
                    .concat(
                        inMemoriamResults.map(im => ({
                            type: resultTypes.inMemoriam,
                            value: im,
                        }))
                    )
                async(acc)
            },
            () => fail()
        )
    }

    const getSyncResults = query => {
        // Add the advanced search row
        const extraRow = getExtraRow(query)

        let results
        if (query) {
            // Search employee in-memory cache
            results = model.searchEmployees(query).slice(0, maxTotal)
            results = results
                .map(e => ({
                    type: resultTypes.employee,
                    value: e,
                }))
                .slice(0, maxEmployees)
        } else {
            // Search recent selections
            if (OW_AUTH_DATA.instant_search && OW_AUTH_DATA.instant_search.record_set) {
                const isMobileWeb = isMobileScreenSize()
                results = OW_AUTH_DATA.instant_search.record_set
                    .filter(({ type }) => !isMobileWeb || type !== 'ConferenceRoom')
                    .slice(0, 5)
                    .map(o => Object.assign(o, { recent: true }))
            } else {
                results = []
            }
        }
        return results.concat([extraRow])
    }

    const getRow = ({ type, value }) => {
        if (type === resultTypes.employee) {
            const { profile_photo_thumbnail: photo, full_name: name, title } = value
            const imgStyle = {
                ...(Boolean(photo) && { backgroundImage: `url(${photo})` }),
            }
            return (
                <div className="typeAheadItem">
                    <div className="avatarBase avatar-size-40 ico-user-photo" style={imgStyle} />
                    <div className="itemDetails">
                        <div className="searchItem itemName">{name}</div>
                        <div className="searchItem itemTitle personTitle">{title}</div>
                    </div>
                    <EmployeeBadgeMetricsWithoutDurationOrangesDependency employee={value} />
                </div>
            )
        } else if (type === resultTypes.office) {
            const { photo_thumbnail: photo, name, total_count: count } = value
            const imgStyle = {
                ...(Boolean(photo) && { backgroundImage: `url(${photo})` }),
            }
            return (
                <div className="typeAheadItem">
                    <div className="avatarBase avatar-size-40 ico-office" style={imgStyle} />
                    <div className="itemDetails">
                        <div className="searchItem itemName">{name}</div>
                        <div className="searchItem itemTitle">{t('instant_search.office_count', { count })}</div>
                    </div>
                </div>
            )
        } else if (type === resultTypes.conferenceRoom) {
            const { display_name: displayName, capacity, slug } = value
            const isSharedDesk = Boolean(slug) && slug.toLowerCase().includes('desk')
            const icoCssClass = isSharedDesk ? 'ico-shared-desk' : 'ico-conference-room'
            const title = isSharedDesk
                ? t('instant_search.shared_desk')
                : capacity
                  ? t('instant_search.room_capacity', { count: capacity })
                  : t('instant_search.conference_room')
            return (
                <div className="typeAheadItem">
                    <div className={`avatarBase avatar-size-40 ${icoCssClass}`} />
                    <div className="itemDetails">
                        <div className="searchItem itemName">{displayName}</div>
                        <div className="searchItem itemTitle">{title}</div>
                    </div>
                </div>
            )
        } else if (type === resultTypes.group) {
            const { photo, name, member_count: count, has_everyone: everyone, has_more: more } = value
            const imgStyle = {
                ...(Boolean(photo) && { backgroundImage: `url(${photo})` }),
            }
            const memberText = everyone
                ? t('instant_search.members_everyone')
                : more
                  ? t('instant_search.members_more', { count })
                  : t('instant_search.members', { count })
            const ico = GroupUtils.getGroupIcon(value)
            return (
                <div className="typeAheadItem">
                    <div className={'avatarBase avatar-size-40 ' + ico} style={imgStyle} />
                    <div className="itemDetails">
                        <div className="searchItem itemName">{name}</div>
                        <div className="searchItem itemTitle">{memberText}</div>
                    </div>
                </div>
            )
        } else if (type === resultTypes.skill) {
            const { name, employee_count: count, synonym_match: match } = value
            return (
                <div className="typeAheadItem">
                    <div className="avatarBase avatar-size-40 ico-skill" />
                    <div className="itemDetails">
                        <div className="searchItem itemName">{name}</div>
                        <div className="searchItem itemTitle">
                            {t('instant_search.skill_count', {
                                count,
                                label: t('skill.label'),
                            })}
                            {match && (
                                <span>
                                    {' '}
                                    <ReactI18next.Trans i18nKey={'instant_search.skill_alias'}>
                                        (Also known as <span>{{ match } as any}</span>)
                                    </ReactI18next.Trans>
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            )
        } else if (type === resultTypes.role) {
            const { name, employee_count: count } = value
            return (
                <div className="typeAheadItem">
                    <div className="avatarBase avatar-size-40 ico-role" />
                    <div className="itemDetails">
                        <div className="searchItem itemName">{name}</div>
                        <div className="searchItem itemTitle">
                            {t('instant_search.role_count', {
                                count,
                            })}
                        </div>
                    </div>
                </div>
            )
        } else if (type === resultTypes.product) {
            const { name, employee_count: count } = value
            return (
                <div className="typeAheadItem">
                    <div className="avatarBase avatar-size-40 ico-product" />
                    <div className="itemDetails">
                        <div className="searchItem itemName">{name}</div>
                        <div className="searchItem itemTitle">
                            {t('instant_search.product_count', {
                                count,
                            })}
                        </div>
                    </div>
                </div>
            )
        } else if (type === resultTypes.inMemoriam) {
            const { full_name: name, profile_photo } = value
            const imgStyle = {
                ...(Boolean(profile_photo) && {
                    backgroundImage: `url(${profile_photo}`,
                }),
            }
            return (
                <div className="typeAheadItem">
                    <div className="avatarBase avatar-size-40 user-photo" style={imgStyle} />
                    <div className="itemDetails">
                        <div className="searchItem itemName">{name}</div>
                        <div className="searchItem itemTitle">In Memoriam</div>
                    </div>
                </div>
            )
        } else if (type === resultTypes.groupPage) {
            return (
                <div className="typeAheadItem">
                    <div className="avatarBase avatar-size-40 ico-community" />
                    <div className="itemDetails">
                        <div className="searchItem itemName">{t('instant_search.all_groups')}</div>
                    </div>
                </div>
            )
        } else if (type === resultTypes.advancedSearch) {
            return (
                <div className="typeAheadItem advancedSearch">
                    <div className="avatarBase avatar-size-40 ico-advanced-search" />
                    <div className="itemDetails">
                        <div className="searchItem itemName">{t('instant_search.advanced_search')}</div>
                    </div>
                </div>
            )
        } else {
            return <span>Error!</span>
        }
    }

    const onSelect = (query, { type, value }, idx, results) => {
        setLastInput(undefined)
        void trackMixpanel(query, { type, value }, idx, results)
        if (type === resultTypes.employee) {
            lscache.set('from_search', 'true')
            lscache.set('window_location', window.location)
            TypeaheadUtils.addToInstantSearch(value, 'Employee', e => e.public_id)
            PlaceManager.getInstance().goToEmployee(
                {
                    public_id: value.public_id,
                    full_name: value.full_name,
                    fromInstantSearch: true,
                },
                true
            )
        } else if (type === resultTypes.office) {
            if (value.is_active) {
                TypeaheadUtils.addToInstantSearch(value, 'Location', l => l.slug)
            }
            PlaceManager.getInstance().goToLocations(
                {
                    slug: value.slug,
                    name: value.name,
                    fromInstantSearch: true,
                },
                true
            )
        } else if (type === resultTypes.conferenceRoom) {
            TypeaheadUtils.addToInstantSearch(
                value,
                'ConferenceRoom',
                ({ slug, location_slug }) => `${location_slug}:${slug}`
            )
            PlaceManager.getInstance().goToLocations(
                {
                    slug: value.location_slug,
                    name: value.location_name,
                    conferenceRoomSlug: value.slug,
                    conferenceRoomName: value.name,
                    fromInstantSearch: true,
                },
                true
            )
        } else if (type === resultTypes.group) {
            lscache.set('group_viewed_source', 'Search')
            TypeaheadUtils.addToInstantSearch(value, 'Group', g => g.slug_name)
            PlaceManager.getInstance().goToGroups({
                slug_name: value.slug_name,
                name: value.name,
                isDlist: value.type === Constants.GroupTypes.DLIST_TYPE,
                fromInstantSearch: true,
            })
        } else if (type === resultTypes.skill) {
            TypeaheadUtils.addToInstantSearch(value, 'Skill', s => s.slug_name)
            PlaceManager.getInstance().goToSearch(
                {
                    sks: value.slug_name,
                    skn: value.name,
                    fromInstantSearch: true,
                },
                true
            )
        } else if (type === resultTypes.role) {
            TypeaheadUtils.addToInstantSearch(value, 'Role', ({ name }) => name)
            PlaceManager.getInstance().goToSearch(
                {
                    an: value.name,
                    fromInstantSearch: true,
                },
                true
            )
        } else if (type === resultTypes.product) {
            TypeaheadUtils.addToInstantSearch(value, 'Product', ({ name }) => name)
            PlaceManager.getInstance().goToSearch(
                {
                    pn: value.name,
                    fromInstantSearch: true,
                },
                true
            )
        } else if (type === resultTypes.inMemoriam) {
            PlaceManager.getInstance().goToInMemoriam({ publicId: value.public_id, name: value.full_name }, true)
        } else if (type === resultTypes.groupPage) {
            PlaceManager.getInstance().goToGroups({})
        } else if (type === resultTypes.advancedSearch) {
            const f = getQueryVariable('f')
            PlaceManager.getInstance().goToSearch({ q: value, f, fromInstantSearch: true }, true)
        }

        return type === resultTypes.advancedSearch ? query : ''
    }

    const onBlur = (query, results) => {
        if (query && lastInput !== query) {
            const employeeCount = results.filter(({ type }) => type === resultTypes.employee).length
            const groupCount = results.filter(({ type }) => type === resultTypes.group).length
            const skillCount = results.filter(({ type }) => type === resultTypes.skill).length
            const officeCount = results.filter(({ type }) => type === resultTypes.office).length
            const conferenceRoomCount = results.filter(({ type }) => type === resultTypes.conferenceRoom).length
            void mixpanelTrack('Instant Search - Abandoned', {
                'Query String': query,
                'Employee Hits': employeeCount,
                'Group Hits': groupCount,
                'Skill Hits': skillCount,
                'Office Hits': officeCount,
                'Conference Room Hits': conferenceRoomCount,
            })
            setLastInput(query)
        }
    }

    const searchQ = isSearch ? q : ''

    return (
        <OWTypeaheadComponent
            getRow={getRow}
            getAsyncSuggestions={getAsyncResults}
            liClass={getLiClass}
            noSpinner={true}
            noSelect={query => ({
                type: resultTypes.advancedSearch,
                value: query,
            })}
            onSelect={onSelect}
            focusFirst={false}
            getSyncSuggestions={getSyncResults}
            wrapperClass="twitter-typeahead"
            placeholder={t('instant_search.placeholder')}
            defaultQuery={searchQ}
            initialFocus={autofocus}
            inputClass="form-control nav-search-input"
            onBlur={onBlur}
        />
    )
}
