import * as Constants from '~/utils/Constants'
import {
    PlaceManager,
    urlForAdminCompany,
    urlForAdminFeatures,
    urlForAdminReports,
    urlForAdminUsers,
    urlForDomainList,
    urlForEmployee,
    urlForEnterADomain,
    urlForGroups,
    urlForHome,
    urlForLocations,
    urlForNewsFeed,
    urlForSearch,
} from '~/utils/PlaceManager'
import * as OWUtils from '~/utils/OWUtils'
import { ServerApi } from '~/utils/ServerApi'
import { OWAuthDataModel } from '~/models/OWAuthDataModel'
import React, { CSSProperties, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useSearchParams } from 'react-router-dom'
import { NavbarUtils } from '~/utils/NavbarUtils'
import { NotificationComponent } from '~/react/navbar/NotificationComponent'
import { NavbarTypeahead } from '~/react/navbar/NavbarTypeahead'
import { OnboardingBannerComponent } from '~/react/navbar/banners/OnboardingBannerComponent'
import { TimelineModerationBannerComponent } from '~/react/navbar/banners/TimelineModerationBannerComponent'
import { PreLaunchModeBannerComponent } from '~/react/navbar/banners/PreLaunchModeBannerComponent'
import { OWApp } from '~/app/OWApp'
import ReactGA from 'react-ga'
import { useOWContext } from '~/utils/OWContext'
import { mixpanelTrack } from '~/utils/MixpanelUtils'
import { LanguagePickerModalComponent } from '~/react/common-components/LanguagePickerModalComponent'
import { Dropdown } from 'react-bootstrap'
import { Global } from '~/global'
import { useQuery } from '@apollo/client'
import { GetBrandingDocument } from '~/generated/graphql'

const CAN_ACCESS_TRANSFER_PORTAL = Global.CAN_ACCESS_TRANSFER_PORTAL

function updateBannerClass(cssClass, check) {
    if (check()) {
        document.body.classList.add(cssClass)
    } else {
        document.body.classList.remove(cssClass)
    }
}

const navTabClass = (position, active) => (position === 'navbar' ? ' navbar-tab ' + (active ? ' active' : '') : '')

function usePathProps() {
    const { pathname } = useLocation()
    return useMemo(() => {
        const isHome = pathname === '/'
        return {
            isHome: isHome,
            noNavLogoBranding: isHome,
            isAdminPage: pathname.startsWith('/admin/'),
            isSearch: pathname.startsWith('/search/'),
            isFeed: pathname.startsWith('/feed/'),
            isEmployees: pathname.startsWith('/employees/') || pathname.startsWith('/e/'),
            isGroups: pathname.startsWith('/groups/'),
            isOffices: pathname.startsWith('/offices/'),
        }
    }, [pathname])
}

function useAdminProps() {
    const { pathname } = useLocation()
    return useMemo(
        () => ({
            isAdminUsers: pathname === '/admin/' || pathname === '/admin/users/',
            isAdminFeatures: pathname === '/admin/features/',
            isAdminReports: pathname === '/admin/reports/',
            isAdminCompany: pathname === '/admin/company/',
        }),
        [pathname]
    )
}

type CustomToggleProps = {
    onClick: (e: React.MouseEvent) => void
    authEmail: string
    profileImg: CSSProperties
    showProfileStrength: boolean
    profileStrength: number
    isActiveAdmin: boolean
}

const UserMenuToggle = React.forwardRef<HTMLAnchorElement, CustomToggleProps>(
    ({ onClick, authEmail, profileImg, showProfileStrength, profileStrength, isActiveAdmin }, ref) => {
        const { t } = useTranslation()

        return (
            <a
                id="navbar_user_menu"
                data-toggle="dropdown"
                aria-haspopup="true"
                title={`Logged in as ${authEmail}`}
                aria-expanded="false"
                ref={ref}
                onClick={e => {
                    e.preventDefault()
                    onClick(e)
                }}
            >
                <div className="nav-profile-photo" style={profileImg} />
                {showProfileStrength && <div className="profile-image-overlay">{profileStrength}%</div>}
                {isActiveAdmin && <div className="admin-mode-profile-photo-badge">{t('navbar.admin')}</div>}
            </a>
        )
    }
)

const MobileMenuToggle = React.forwardRef<HTMLButtonElement, { onClick: (e: React.MouseEvent) => void }>(
    ({ onClick }, ref) => {
        const { t } = useTranslation()

        return (
            <button className={'mobile-menu-button'} id={'navbar-menu'} onClick={onClick} ref={ref}>
                {t('navbar.menu')}
            </button>
        )
    }
)

export const NavbarComponent = () => {
    const { t } = useTranslation('common')
    const [mobileSearch, setMobileSearch] = useState<boolean>(false)
    const [searchParams] = useSearchParams()
    const q = searchParams.get('q')

    const clickMobileSearch = () => {
        setMobileSearch(!mobileSearch)
    }
    const { data: brandingData } = useQuery(GetBrandingDocument)
    const companyConfig = brandingData?.company?.companyConfig
    const navbarLogo = companyConfig?.navbarLogo
    const {
        employee,
        company,
        isActiveAdmin,
        isAuthenticated,
        isAdmin,
        isGuest,
        isGhost,
        hideSearch,
        hideNav,
        tagsEnabled,
        groupsEnabled,
        onlyLogout,
        timelineModerationDatetime,
    } = useOWContext()
    const { isHome, noNavLogoBranding, isAdminPage, isSearch } = usePathProps()

    // TODO: convert
    const showConfigBanner = NavbarUtils.shouldShowConfigBanner()
    const showModerationBanner = NavbarUtils.shouldShowModerationBanner(timelineModerationDatetime)
    const showOnboardingBanner = NavbarUtils.shouldShowOnboardingBanner()
    const lightNavbar = Boolean(companyConfig?.lightNavbar)
    const homeHref = urlForHome()
    const homeClick = OWUtils.onFilteredLeftClick(() => PlaceManager.getInstance().goToHome(isAuthorized))
    const searchHref = urlForSearch({ tp: 1 })

    const profileHref = employee ? urlForEmployee({ public_id: employee.public_id }) : ''

    const logoutHref = '/logout/'
    const isAuthorized = isAuthenticated && (isGuest || isGhost || Boolean(OWAuthDataModel.getAuthPublicId()))
    const hasCompany = Boolean(company)
    const profileStrength = (employee || { profile_strength: 100 }).profile_strength
    const profileImg = {
        ...(employee &&
            employee.profile_photo_thumbnail && {
                backgroundImage: `url(${employee.profile_photo_thumbnail})`,
            }),
    }
    const authEmail = (employee || { email: 'Guest' }).email
    const navBarImg = {
        ...(navbarLogo && { backgroundImage: `url(${navbarLogo})` }),
    }
    const { label: langLabel, icon: langIcon, lang } = OWApp.getCurrentLanguage()

    const noLogoBranding = noNavLogoBranding || !navbarLogo
    const hideNotificationTray = Boolean(OWUtils.hasFeature(Constants.Features.HIDE_NOTIFICATION_TRAY) || isAdminPage)
    const hideProfileStrength = OWUtils.hasFeature(Constants.Features.HIDE_PROFILE_STRENGTH)
    const showProfileStrength = !hideProfileStrength && profileStrength !== 100

    const shouldHideSearch = Boolean(!isAuthorized || isHome || hideSearch || isAdminPage)

    const adminPageHref = urlForAdminUsers({})
    const [showLanguagePicker, setShowLanguagePicker] = useState(false)

    const clickToggleAdminMode = () => {
        if (employee) {
            const prom = isActiveAdmin
                ? ServerApi.disableAdminMode(employee?.public_id)
                : ServerApi.enableAdminMode(employee?.public_id)
            void prom.then(() => {
                ReactGA.ga('send', 'event', 'link', 'click', 'toggle admin mode')
                PlaceManager.getInstance().goToHome()
            })
        }
    }

    useEffect(() => {
        if (window.innerWidth > 768 && mobileSearch) {
            setMobileSearch(false)
        }
    }, [mobileSearch])
    const location = useLocation()
    useEffect(() => {
        updateBannerClass('config-banner-displayed', () => NavbarUtils.shouldShowConfigBanner())
        updateBannerClass('feed-banner-displayed', () =>
            NavbarUtils.shouldShowModerationBanner(timelineModerationDatetime)
        )
        updateBannerClass('onboarding-banner-displayed', () => NavbarUtils.shouldShowOnboardingBanner())
    }, [location, timelineModerationDatetime])

    return (
        <div id="navbar-container" className="navbar navbar-fixed-top hide-on-native-mobile">
            <LanguagePickerModalComponent
                currentLanguage={lang}
                show={showLanguagePicker}
                hide={() => setShowLanguagePicker(false)}
            />
            {showConfigBanner && employee && <PreLaunchModeBannerComponent authEmployee={employee} />}
            {showModerationBanner && (
                <TimelineModerationBannerComponent
                    timeline_moderation_datetime={timelineModerationDatetime || null}
                    timeline_moderation_is_active={Boolean(company?.timeline_moderation_is_active)}
                />
            )}
            {showOnboardingBanner && <OnboardingBannerComponent />}
            <div
                className={
                    'ow-navbar ow-b-bgc-primary' +
                    (lightNavbar ? ' navbar-theme-light' : '') +
                    (mobileSearch ? ' mobile-search-active' : '') +
                    (shouldHideSearch ? ' no-search' : '')
                }
            >
                <div className="ow-navbar-wrapper">
                    <div className="ow-navbar-leftcol">
                        {isAuthorized && !hideNav && (
                            <Dropdown id={'navbar-menu-dropdown'} className={'nav-section navbar-menu'}>
                                <Dropdown.Toggle as={MobileMenuToggle} />
                                <Dropdown.Menu>
                                    {isAdminPage ? <AdminDropdownNavTabs /> : <DropdownNavTabs />}
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                        <div className="nav-section navbar-branding">
                            <Link to={homeHref}>
                                <div
                                    className={
                                        'navbar-orgwiki-brand-container' +
                                        (hasCompany && !noLogoBranding ? ' hidden-xs hidden-sm hidden-md' : '')
                                    }
                                />
                                {hasCompany && !noLogoBranding && navbarLogo && (
                                    <div className="pipe-logo-separator hidden-xs hidden-sm hidden-md" />
                                )}
                                {hasCompany && !noLogoBranding && navbarLogo && (
                                    <div className="navbar-customer-brand" style={navBarImg} />
                                )}
                            </Link>
                        </div>
                    </div>
                    <div className="ow-navbar-rightcol">
                        {!shouldHideSearch && (
                            <div className="nav-section navbar-search">
                                <div className="mobile-search-button" onClick={clickMobileSearch} />
                                <NavbarTypeahead
                                    tagsEnabled={tagsEnabled}
                                    groupsEnabled={groupsEnabled}
                                    q={q ?? undefined}
                                    isSearch={isSearch}
                                />
                            </div>
                        )}
                        {shouldHideSearch && <div />}
                        {isAdminPage && (
                            <div className="nav-section exit-admin">
                                <button className="btn btn-exitAdmin" onClick={homeClick}>
                                    {t('navbar.admin_page_leave')}
                                </button>
                            </div>
                        )}
                        <div className={'nav-section navbar-tabs ' + (isAdminPage ? ' admin-nav' : '')}>
                            <ul className="navbar-tabs-list">
                                {isAuthorized && (
                                    <>
                                        {isAdminPage ? (
                                            <AdminNavTabs position={'navbar'} />
                                        ) : (
                                            <NavTabs position={'navbar'} />
                                        )}
                                        {!hideNotificationTray && <NotificationComponent />}
                                    </>
                                )}
                                {isAuthenticated && (
                                    <Dropdown className="navbar-profile-photo is-admin admin-enabled" align={'end'}>
                                        <Dropdown.Toggle
                                            as={UserMenuToggle}
                                            authEmail={authEmail}
                                            profileImg={profileImg}
                                            showProfileStrength={showProfileStrength}
                                            profileStrength={profileStrength ?? 0}
                                            isActiveAdmin={isActiveAdmin}
                                        >
                                            <div className="nav-profile-photo" style={profileImg} />
                                            {showProfileStrength && (
                                                <div className="profile-image-overlay">{profileStrength}%</div>
                                            )}
                                            {isActiveAdmin && (
                                                <div className="admin-mode-profile-photo-badge">
                                                    {t('navbar.admin')}
                                                </div>
                                            )}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu
                                            className="dropdown-menu dropdown-menu-right"
                                            aria-labelledby="navbar_user_menu"
                                            id="ul-navbar-menu-items-popover"
                                        >
                                            {Boolean(employee) && !onlyLogout && (
                                                <Dropdown.Item as={Link} to={profileHref}>
                                                    {t('navbar.my_profile')}
                                                </Dropdown.Item>
                                            )}
                                            {!onlyLogout && OWUtils.languagePickerEnabled() && (
                                                <Dropdown.Item onClick={() => setShowLanguagePicker(true)}>
                                                    {langIcon} {langLabel}
                                                </Dropdown.Item>
                                            )}
                                            {isAdmin && !onlyLogout && (
                                                <>
                                                    <li role="separator" className="divider" />
                                                    <Dropdown.Item
                                                        id={'toggle-admin-mode-link'}
                                                        onClick={clickToggleAdminMode}
                                                    >
                                                        {isActiveAdmin
                                                            ? t('navbar.admin_exit')
                                                            : t('navbar.admin_enter')}
                                                    </Dropdown.Item>
                                                </>
                                            )}
                                            {isActiveAdmin && !onlyLogout && !isAdminPage && (
                                                <>
                                                    <Dropdown.Item to={adminPageHref} as={Link}>
                                                        {t('navbar.admin_page')}
                                                    </Dropdown.Item>
                                                </>
                                            )}
                                            {isActiveAdmin && !onlyLogout && isAdminPage && (
                                                <>
                                                    <Dropdown.Item as={Link} to={homeHref}>
                                                        {t('navbar.admin_page_leave')}
                                                    </Dropdown.Item>
                                                </>
                                            )}
                                            {CAN_ACCESS_TRANSFER_PORTAL && (
                                                <>
                                                    <li role="separator" className="divider" />
                                                    <Dropdown.Item
                                                        as={Link}
                                                        to={searchHref}
                                                        id={'transfer-portal-link'}
                                                    >
                                                        {t('navbar.transfer_portal')}
                                                    </Dropdown.Item>
                                                </>
                                            )}
                                            {!onlyLogout && Boolean(employee) && (
                                                <li role="separator" className="divider" />
                                            )}
                                            <Dropdown.Item as={Link} to={getOtherDomainLink()}>
                                                Sign In to another account
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                href={logoutHref}
                                                className={'logout_link'}
                                                onClick={() => void mixpanelTrack('Logged Out')}
                                            >
                                                {t('navbar.logout')}
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function getOtherDomainLink() {
    const domainMap = OWAuthDataModel.getMultiDomainSessions()
    const subdomainCount = Object.keys(domainMap).length

    // If there are no subdomains except the current one, go to the enter a domain page; otherwise, go to the
    // domain-list page
    if (subdomainCount <= 1) {
        return urlForEnterADomain()
    } else {
        return urlForDomainList()
    }
}

const DropdownNavTabs = () => {
    const { groupsEnabled } = useOWContext()
    const feedHref = urlForNewsFeed()
    const peopleHref = OWUtils.hasFeature(Constants.Features.SHOW_SEARCH_STYLE_HOMEPAGE)
        ? urlForSearch({})
        : urlForEmployee({})
    const groupsHref = urlForGroups({})
    const officesHref = urlForLocations({})
    const hideFeed = OWUtils.hasFeature(Constants.Features.HIDE_NEWS_FEED)
    const showFeed = !hideFeed || OWAuthDataModel.isActiveAdmin()
    const showDListHomepage = OWUtils.hasFeature(Constants.Features.SHOW_DLISTS_HOMEPAGE)
    const { t } = useTranslation('common')

    const feed = (
        <Dropdown.Item
            as={Link}
            className={'tab-feed'}
            key="tab-feed"
            to={feedHref}
            onClick={() => void mixpanelTrack('News Feed nav bar clicked')}
        >
            {t('navbar.feed')}
        </Dropdown.Item>
    )
    const people = (
        <Dropdown.Item
            as={Link}
            className={'tab-people'}
            key="tab-people"
            to={peopleHref}
            onClick={() => void mixpanelTrack('Org Chart nav bar clicked')}
        >
            {t('navbar.people')}
        </Dropdown.Item>
    )
    const groups = (
        <Dropdown.Item
            as={Link}
            className={'tab-groups'}
            key="tab-groups"
            to={groupsHref}
            onClick={() => void mixpanelTrack('Groups nav bar clicked')}
        >
            {t('navbar.groups')}
        </Dropdown.Item>
    )
    const offices = (
        <Dropdown.Item
            as={Link}
            className={'tab-offices'}
            key="tab-offices"
            to={officesHref}
            onClick={() => void mixpanelTrack('Offices nav bar clicked')}
        >
            {t('navbar.offices')}
        </Dropdown.Item>
    )
    return showDListHomepage ? (
        <>
            {groupsEnabled && groups}
            {people}
            {offices}
        </>
    ) : (
        <>
            {showFeed && feed}
            {people}
            {groupsEnabled && groups}
            {offices}
        </>
    )
}

const AdminDropdownNavTabs = () => {
    const { t } = useTranslation('common')
    const usersHref = urlForAdminUsers({})
    const featuresHref = urlForAdminFeatures({})
    const reportsHref = urlForAdminReports()
    const companyHref = urlForAdminCompany({})
    return (
        <>
            <Dropdown.Item className={'tab-users'} key="users" as={Link} to={usersHref}>
                {t('navbar.admin_users')}
            </Dropdown.Item>
            <Dropdown.Item className={'tab-features'} key="features" as={Link} to={featuresHref}>
                {t('navbar.admin_features')}
            </Dropdown.Item>
            <Dropdown.Item className={'tab-reports'} key="reports" as={Link} to={reportsHref}>
                {t('navbar.admin_reports')}
            </Dropdown.Item>
            <Dropdown.Item className={'tab-company-config'} key="company" as={Link} to={companyHref}>
                {t('navbar.admin_company')}
            </Dropdown.Item>
        </>
    )
}

const NavTabs = (props: { position?: string }) => {
    const { position } = props
    const { isFeed, isEmployees, isGroups, isOffices } = usePathProps()
    const { groupsEnabled } = useOWContext()

    const feedHref = urlForNewsFeed()
    const peopleHref = OWUtils.hasFeature(Constants.Features.SHOW_SEARCH_STYLE_HOMEPAGE)
        ? urlForSearch({})
        : urlForEmployee({})
    const groupsHref = urlForGroups({})
    const officesHref = urlForLocations({})
    const hideFeed = OWUtils.hasFeature(Constants.Features.HIDE_NEWS_FEED)
    const showFeed = !hideFeed || OWAuthDataModel.isActiveAdmin()
    const showDListHomepage = OWUtils.hasFeature(Constants.Features.SHOW_DLISTS_HOMEPAGE)
    const { t } = useTranslation('common')

    const feed = (
        <li className={'tab-feed' + navTabClass(position, isFeed)} key="tab-feed">
            <Link
                className="news_feed_link"
                to={feedHref}
                onClick={() => void mixpanelTrack('News Feed nav bar clicked')}
            >
                {position === 'navbar' ? t('navbar.feed_sm') : t('navbar.feed')}
            </Link>
        </li>
    )
    const people = (
        <li className={'tab-people' + navTabClass(position, isEmployees)} key="tab-people">
            <Link
                className={'org_chart_link'}
                to={peopleHref}
                onClick={() => void mixpanelTrack('Org Chart nav bar clicked')}
            >
                {t('navbar.people')}
            </Link>
        </li>
    )
    const groups = (
        <li className={'tab-groups' + navTabClass(position, isGroups)} key="tab-groups">
            <Link className="groups_link" to={groupsHref} onClick={() => void mixpanelTrack('Groups nav bar clicked')}>
                {t('navbar.groups')}
            </Link>
        </li>
    )
    const offices = (
        <li className={'tab-offices' + navTabClass(position, isOffices)} key="tab-offices">
            <Link
                className="offices_link"
                to={officesHref}
                onClick={() => void mixpanelTrack('Offices nav bar clicked')}
            >
                {t('navbar.offices')}
            </Link>
        </li>
    )
    return showDListHomepage ? (
        <>
            {groupsEnabled && groups}
            {people}
            {offices}
        </>
    ) : (
        <>
            {showFeed && feed}
            {people}
            {groupsEnabled && groups}
            {offices}
        </>
    )
}

const AdminNavTabs = ({ position }: { position?: string }) => {
    const { isAdminUsers, isAdminFeatures, isAdminReports, isAdminCompany } = useAdminProps()
    const { t } = useTranslation('common')
    const usersHref = urlForAdminUsers({})
    const featuresHref = urlForAdminFeatures({})
    const reportsHref = urlForAdminReports()
    const companyHref = urlForAdminCompany({})
    return (
        <>
            <li className={'tab-users ' + navTabClass(position, isAdminUsers)} key="users">
                <Link to={usersHref}>{t('navbar.admin_users')}</Link>
            </li>
            <li className={'tab-features ' + navTabClass(position, isAdminFeatures)} key="features">
                <Link to={featuresHref}>{t('navbar.admin_features')}</Link>
            </li>
            <li className={'tab-reports ' + navTabClass(position, isAdminReports)} key="reports">
                <Link to={reportsHref}>{t('navbar.admin_reports')}</Link>
            </li>
            <li className={'tab-company-config ' + navTabClass(position, isAdminCompany)} key="company">
                <Link to={companyHref}>{t('navbar.admin_company')}</Link>
            </li>
        </>
    )
}
