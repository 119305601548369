/**
 * Created by baselqumsiyeh on 8/4/16.
 */
import 'react-app-polyfill/stable'
import { Global } from '~/global'
import moment, { Moment } from 'moment'

import { LanguageInfo } from './types'
import { flushStaleLocalStorage, mergeObjects } from '~/utils/OWUtils'
import { Languages, OWPageType } from '~/utils/Constants'
import { PlaceManager } from '~/utils/PlaceManager'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { Translations } from '~/i18n/Translations'
import * as analytics from '~/analytics'
import ReactGA from 'react-ga'
import { EmployeesModel } from '~/models/EmployeesModel'

/**
 * A object that represents the top-level application.
 * Includes global data and context
 * Responsible for defining the JS Execution pipeline
 * @constructor
 */

declare let SHOULD_ONBOARD

export class OWApp {
    // Data about the current logged in user and all the related data about it.
    // Right now just a plain JS Object based off OWAuthData.py object
    authData: any
    currentPageType
    i18n: typeof i18n | undefined
    private static _instance: OWApp

    constructor() {
        this.currentPageType = OWPageType.UNKNOWN

        flushStaleLocalStorage()
    }

    /**
     * This is where everything starts.
     * Kicks off the whole app, from a JS perspective
     */
    start() {
        this.setupi18n()

        // Init the Navbar
        this.loadAuthDataSentFromServer()

        // make sure auth employee is set in Employees cache
        this.authData.employee && EmployeesModel.insertEmployee(this.authData.employee)

        analytics.setup()
        const userId = this.authData?.employee?.id || ''
        ReactGA.set({ userId })

        const placeManager = new PlaceManager(this)
        PlaceManager.setInstance(placeManager)
        placeManager.router.routeCurrentUrl()

        let href = window.location.href
        if (SHOULD_ONBOARD && window.location.pathname === '/') {
            SHOULD_ONBOARD = false
            href = '/my-profile/welcome/'
        }
        // Initialize initial state
        history.replaceState(
            {
                href: window.location.href,
                title: document.title,
                args: null,
                id: placeManager.stateMaster,
            },
            (document.title || '').toString(),
            href
        )
    }

    setupi18n() {
        const lang = Global.OW_AUTH_DATA.language || (Global.OW_AUTH_DATA.enabled_languages || ['en'])[0]

        const messages = mergeObjects(Translations, Global.OW_AUTH_DATA.localized_text || {})

        // Set up i18n
        void i18n.use(initReactI18next).init({
            // react: {useSuspense: false},
            resources: messages,
            lng: lang,
            keySeparator: false,
            interpolation: {
                escapeValue: false,
                format: (value, format, lang) => {
                    if (value instanceof moment) {
                        const m = value as Moment
                        if (format === 'fromNow') {
                            return m.fromNow()
                        } else {
                            return m.format(format)
                        }
                    } else if (value instanceof Date) {
                        return moment(value).format(format)
                    } else if (value instanceof Number) {
                        return Intl.NumberFormat(lang).format(value.valueOf())
                    } else {
                        return value
                    }
                },
            },
        })
        moment.locale(lang)
        i18n.on('languageChanged', lng => moment.locale(lng))
        i18n.setDefaultNamespace('common')
        this.i18n = i18n
        document.dispatchEvent(new Event('OWApp.translationsReady'))
    }

    /**
     * Gets the current language
     */
    static getCurrentLanguage(): LanguageInfo {
        const lang = i18n.language
        return { ...Languages[lang], lang }
    }

    /**
     * ONLY DO THIS FOR OWPageType.PAGE_VIEW_CONTROLLER_BASED !!!
     * We should move away from this pattern
     *
     * The server sends us Auth Data via a variable in the base template
     * This data is based of OWAuthData.py, and is encoded into the JS variable as a JSON string
     */
    loadAuthDataSentFromServer() {
        // Just parse the JSON string and save it to our instance variable
        this.authData = Global.OW_AUTH_DATA
    }

    static getInstance(): OWApp {
        return this._instance
    }
    static setInstance(instance: OWApp) {
        this._instance = instance
    }
}
