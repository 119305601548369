import { Global } from '~/global'
import { hasFeature } from './OWUtils'
import { DListProviderIds, Features, GroupSubTypes, GroupTypes } from './Constants'
import { COMMUNITY_PAGE_TABS, GROUPS_PAGE_TABS, LIST_PAGE_TABS } from '~/react/tabs'
import { TFunction } from 'i18next'

/**
 * Gets the page title for a list and view flag combination
 * @param {string} [name] The list name
 * @param {bool} [isDlist] True if the group is a dlist
 * @param {string} [v] The navigation tab flag
 * @param {func} [t] The app's translation function
 */

export function getPageTitle({ name, isDlist, v, t }: { name?: string; isDlist?: boolean; v?: string; t?: TFunction }) {
    if (name) {
        switch (v) {
            case LIST_PAGE_TABS.edit:
            case COMMUNITY_PAGE_TABS.edit:
                return `${name}: Settings | OrgWiki`
            case LIST_PAGE_TABS.history:
            case COMMUNITY_PAGE_TABS.history:
                return `${name}: History | OrgWiki`
            case LIST_PAGE_TABS.members:
            case COMMUNITY_PAGE_TABS.members:
                return `${name}: Members | OrgWiki`
            case LIST_PAGE_TABS.where:
                return `${name}: Where Used | OrgWiki`
            case COMMUNITY_PAGE_TABS.posts:
                return `${name}: Posts | OrgWiki`
            case COMMUNITY_PAGE_TABS.overview:
                return `${name}: Overview | OrgWiki`
            case COMMUNITY_PAGE_TABS.pendingRequests:
                return `${name}: Join Requests`
            case LIST_PAGE_TABS.import:
                return `${name}: Import Members`
            default:
                return isDlist ? `${name}: Members | OrgWiki` : `${name}: Overview | OrgWiki`
        }
    } else {
        switch (v) {
            case GROUPS_PAGE_TABS.createDL:
                return 'New Distribution List | OrgWiki'
            case GROUPS_PAGE_TABS.allDLs:
                return 'All Distribution Lists | OrgWiki'
            case GROUPS_PAGE_TABS.customDLs:
                return 'Custom Distribution Lists | OrgWiki'
            case GROUPS_PAGE_TABS.myDLs:
                return 'My Distribution Lists | OrgWiki'
            case GROUPS_PAGE_TABS.allCommunities:
                return `All ${getCommunityLabel(true, false, t)} | OrgWiki`
            case GROUPS_PAGE_TABS.createCommunity:
                return `New ${getCommunityLabel(false, false, t)} | OrgWiki`
            case GROUPS_PAGE_TABS.localCommunities:
                return `Local ${getCommunityLabel(true, false, t)} | OrgWiki`
            default:
                return `My ${getCommunityLabel(true, false, t)} | OrgWiki`
        }
    }
}

export function canEditDList({ sub_type: subType, is_owner: isOwner, is_read_only: isReadOnly, is_locked: isLocked }) {
    return (
        subType !== GroupSubTypes.AUTO_DLIST_TYPE &&
        (Global.IS_ACTIVE_ADMIN || isOwner) &&
        !Global.IS_GUEST &&
        !isReadOnly &&
        !isLocked
    )
}

export function canExportDlist({ is_owner: isOwner }) {
    return Global.IS_ACTIVE_ADMIN || isOwner
}

export function hasPendingRequestsTab({ invite_only, is_private }) {
    return invite_only && !is_private
}

export function canEditCommunity({ is_owner: isOwner }) {
    return isOwner || Global.IS_ACTIVE_ADMIN
}

/**
 * Gets the name of the active DList provider
 * @param {Boolean} [useNBSP] If true, replace spaces in name with &nbsp;
 * @return {string} The provider name
 */
export function getDListProviderName(useNBSP) {
    const company = Global.OW_AUTH_DATA.company
    const providerId = company?.dlist_provider
    let str = 'unknown'
    if (providerId === DListProviderIds.GOOGLE) {
        str = 'G Suite'
    } else if (providerId === DListProviderIds.OFFICE_365) {
        str = 'Office 365'
    }
    if (useNBSP) {
        str = str.replaceAll(' ', '\u00A0')
    }
    return str
}

export function getGroupIcon(group) {
    if (group.type === GroupTypes.DLIST_TYPE) {
        return getDListIcon(group)
    } else {
        return ' ico-community'
    }
}

export function getDListIcon(dlist) {
    const isAuto = dlist.sub_type === GroupSubTypes.AUTO_DLIST_TYPE
    const isOffical = Boolean(dlist.official_location)
    const isLocation = Boolean(dlist.location)
    const isRoleProduct = Boolean(dlist.is_role_product)
    const isCountry = Boolean(dlist.country_code)

    if (isLocation) {
        return ' ico-office'
    } else if (isCountry) {
        return ' ico-country-dlist'
    } else if (isRoleProduct) {
        if (dlist.email.startsWith('ow.role')) {
            return ' ico-role dlist'
        } else {
            return ' ico-product dlist'
        }
    } else if (isAuto) {
        return ' ico-dlist-org'
    } else if (isOffical) {
        return ' ico-office-dlist'
    } else {
        return ' ico-dlist-custom'
    }
}

export function dlistsEnabled() {
    return Boolean(Global.OW_AUTH_DATA.company && Global.OW_AUTH_DATA.company.dist_lists_enabled)
}

export function communitiesEnabled() {
    return hasFeature(Features.COMMUNITIES_ENABLED)
}

export function groupsEnabled() {
    return dlistsEnabled() || communitiesEnabled()
}

export function dlistDomain() {
    return dlistsEnabled() ? Global.OW_AUTH_DATA.company?.dist_lists_domain : null
}

export function suggestEmailPartial(name) {
    const r = /[^\d\s._a-z-]+/g
    return name
        .toLocaleLowerCase()
        .replaceAll(r, '') // Trim special characters
        .replaceAll(/\s+/g, '.') // Replace whitespace with dots
        .replaceAll(/\.\.+/g, '.') // de-dupe dots
        .replaceAll(/(^\.+)|(\.+$)/g, '') // trim dots off ends
}

export function addDomain(partial) {
    return `${partial}@${dlistDomain()}`.toLocaleLowerCase()
}

/**
 * Gets the label for communities
 */
export function getCommunityLabel(plural: boolean, lowercase: boolean, t?: TFunction) {
    let value = ''
    if (hasFeature(Features.SHOW_TEAMS_DEMO)) {
        if (plural) {
            value = t ? t('profile_page.teams') : 'Teams'
        } else {
            value = t ? t('profile_page.team') : 'Team'
        }
    } else {
        if (plural) {
            value = t ? t('profile_page.communities') : 'Communities'
        } else {
            value = t ? t('profile_page.community') : 'Community'
        }
    }
    if (lowercase) {
        value = value.toLocaleLowerCase()
    }
    return value
}

export const isValidTab = (socialEnabled: boolean, dlistsEnabled: boolean, tab?: string) => {
    const tabs = GROUPS_PAGE_TABS
    const adminDLs = Boolean((Global.OW_AUTH_DATA.company || {}).dist_lists_admin_only)
    const canCreateDL = (!Global.IS_GUEST && !adminDLs) || Global.IS_ACTIVE_ADMIN
    const canCreateCommunity = !Global.IS_GUEST
    const workAnywhereEnabled = hasFeature(Features.WORK_ANYWHERE_ENABLED)
    return Object.keys(tabs)
        .map(k => tabs[k])
        .filter(
            t =>
                (t !== tabs.myDLs || dlistsEnabled) && // Ensure Dlists Enabled
                (t !== tabs.customDLs || dlistsEnabled) &&
                (t !== tabs.allDLs || dlistsEnabled) &&
                (t !== tabs.createDL || (canCreateDL && dlistsEnabled)) && // Ensure can create
                (t !== tabs.myCommunities || socialEnabled) && // Ensure Communities Enabled
                (t !== tabs.allCommunities || socialEnabled) &&
                (t !== tabs.createCommunity || (canCreateCommunity && socialEnabled)) &&
                (t !== tabs.localCommunities || workAnywhereEnabled)
        ) // Ensure can create
        .includes(tab)
}

export const getDefaultTab = (socialEnabled: boolean) => {
    return socialEnabled ? GROUPS_PAGE_TABS.myCommunities : GROUPS_PAGE_TABS.myDLs
}

/**
 * Determines if In-Memoriams are enabled
 * @returns {Boolean}
 */
export function hasInMemoriams() {
    return communitiesEnabled()
}
