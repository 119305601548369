import { Global } from '~/global'
import { CompanyFeaturePropertyMap } from '~/utils/Constants'
import { updateBrandingStyles } from '~/utils/StyleUtils'
import Cookies from 'js-cookie'
import { AuthEmployee, Company, Employee, Id } from '~/app/types'
import { ComponentBase } from '~/react/utils/ComponentBase'

let _instance: OWAuthDataModel

export class OWAuthDataModel {
    private employeeSubscriptions: any[]
    private companySubscriptions: any[]

    static getInstance() {
        if (!_instance) {
            _instance = new OWAuthDataModel()
        }
        return _instance
    }

    constructor() {
        this.employeeSubscriptions = []
        this.companySubscriptions = []
    }

    static getAuthEmployee(): AuthEmployee | null {
        return Global.CURRENT_EMPLOYEE_PUBLIC_ID ? (Global.OW_AUTH_DATA.employee ?? null) : null
    }

    static getAuthCompany(): Company | null {
        return Global.COMPANY_ID ? (Global.OW_AUTH_DATA.company ?? null) : null
    }

    static getAuthPublicId(): string | undefined {
        return OWAuthDataModel.getAuthEmployee()?.public_id
    }

    static getAuthEmployeeId(): Id | undefined {
        return OWAuthDataModel.getAuthEmployee()?.id
    }

    static maybeUpdateAuthEmployee(employee: Employee) {
        if (employee.public_id && employee.public_id === OWAuthDataModel.getAuthPublicId()) {
            OWAuthDataModel.getInstance().updateAuthEmployee(employee)
        }
    }

    static isActiveAdmin() {
        return Global.IS_ACTIVE_ADMIN
    }

    static isBoardOfDirectors() {
        return Global.IS_BOARD_OF_DIRECTORS
    }

    static getMultiDomainSessions() {
        const allSessionsCookie = Cookies.get('allSessions') || ''
        let allSessions = {}
        try {
            // Some browsers don't allow comma
            allSessions = JSON.parse(JSON.parse('"' + allSessionsCookie.replaceAll(String.raw`\054`, ',') + '"'))
        } catch {
            //
        }
        return allSessions
    }

    /**
     * Register a component for employee updates
     * @param component A react component
     */
    subscribeToEmployeeUpdate(component: ComponentBase<unknown, unknown>) {
        this.employeeSubscriptions.push({ component })
    }

    /**
     * Unsubscribe from employee updates
     * @param component The component to cancel the subscription for
     */
    unsubscribeToEmployeeUpdate(component: ComponentBase<unknown, unknown>) {
        this.employeeSubscriptions = this.employeeSubscriptions.filter(
            ({ component: subComponent }) => component !== subComponent
        )
    }

    /**
     * Register a component for company updates
     * @param component A react component
     */
    subscribeToCompanyUpdate(component: ComponentBase<unknown, unknown>) {
        this.companySubscriptions.push({ component })
    }

    /**
     * Unsubscribe from company updates
     * @param {ComponentBase} component The component to cancel the subscription for
     */
    unsubscribeToCompanyUpdate(component) {
        this.companySubscriptions = this.companySubscriptions.filter(
            ({ component: subComponent }) => component !== subComponent
        )
    }

    /**
     * Trigger an update of the auth employee
     * @param employee the updated employee
     */
    updateAuthEmployee(employee: Employee) {
        const authEmployee = OWAuthDataModel.getAuthEmployee()
        if (authEmployee) {
            Object.assign(authEmployee, employee)
        }
        Global.CURRENT_EMPLOYEE_PUBLIC_ID = OWAuthDataModel.getAuthPublicId()
        this.employeeSubscriptions.forEach(({ component } = {}) => {
            try {
                component.forceUpdate()
            } catch (e) {
                console.error(e)
            }
        })
    }

    /**
     * Trigger an update of the auth company
     * @param {Object} company The updated company
     */
    updateAuthCompany(company) {
        if (company.company_config) {
            updateBrandingStyles(company.company_config)
        }
        const authCompany = OWAuthDataModel.getAuthCompany()
        if (authCompany) {
            Object.assign(authCompany, company)
        }
        Global.OW_AUTH_DATA.features = this._getUpdatedFeaturesFromCompany(company)
        this.companySubscriptions.forEach(({ component } = {}) => {
            try {
                component.forceUpdate()
            } catch (e) {
                console.error(e)
            }
        })
        return OWAuthDataModel.getAuthCompany()
    }

    /**
     * @param company The updated company
     * @returns An updated list of features
     */
    _getUpdatedFeaturesFromCompany(company: Company) {
        const currentFeatures = Object.fromEntries((Global.OW_AUTH_DATA.features || []).map(f => [f, 1]))
        const propertyMap = CompanyFeaturePropertyMap
        Object.keys(propertyMap).forEach(k => {
            const { flag } = propertyMap[k]
            const enabled = company[k]
            if (enabled && !currentFeatures[flag]) {
                currentFeatures[flag] = 1
            } else if (!enabled && currentFeatures[flag]) {
                delete currentFeatures[flag]
            }
        })
        return Object.keys(currentFeatures)
    }
}
