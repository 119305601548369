/**
 * Adds an object to a form data in a format understood by django parsers
 * @param {FormData} formData The form data
 * @param {*} object the object
 * @param {string} parentKey The field name
 */
export function addToFormData(formData, object, parentKey) {
    if (object && typeof object === 'object' && !(object instanceof Date) && !(object instanceof File)) {
        if (Array.isArray(object)) {
            if (object.length > 0) {
                object.forEach((value, idx) => {
                    addToFormData(formData, object[idx], parentKey ? `${parentKey}[${idx}]` : idx)
                })
            } else {
                formData.append(parentKey, '')
            }
        } else {
            Object.keys(object).forEach(key => {
                addToFormData(formData, object[key], parentKey ? `${parentKey}.${key}` : key)
            })
        }
    } else {
        const value = object == null ? '' : object
        formData.append(parentKey, value)
    }
}
