import { Id, TimelineEntry } from '~/app/types'
import * as OWUtils from '~/utils/OWUtils'
import * as Constants from '~/utils/Constants'

/**
 * Gets a set of publicIds that need to be resolved to render a timeline entry
 */
export const getEmployeeIdsToResolve = (entry: Partial<TimelineEntry>) => {
    const ids = new Set<Id>()
    const { employee_id, new_manager_id, likes, comments, is_ghost } = entry
    if (employee_id && !is_ghost) ids.add(employee_id)
    if (new_manager_id) ids.add(new_manager_id)
    likes?.filter(({ employee_id }) => employee_id).forEach(({ employee_id }) => ids.add(employee_id))
    comments
        ?.filter(({ author_id, is_ghost }) => author_id && !is_ghost)
        .forEach(({ author_id }) => {
            if (author_id) ids.add(author_id)
        })
    return ids
}

export const wistiaUrlToHashedID = (url: string) => {
    return url.split('/')[5].split('.')[0]
}

export const getEntryTypes = (filterType: string): string[] => {
    const jobPostsEnabled = OWUtils.hasFeature(Constants.Features.JOB_POSTS_ENABLED)
    if (jobPostsEnabled) {
        return (
            {
                [Constants.FeedTabs.posts]: [
                    Constants.TimelineEntryTypes.postText,
                    Constants.TimelineEntryTypes.postPhoto,
                    Constants.TimelineEntryTypes.postVideo,
                    Constants.TimelineEntryTypes.jobPost,
                ],
                [Constants.FeedTabs.jobs]: [Constants.TimelineEntryTypes.jobPost],
                [Constants.FeedTabs.reportingOrTitleChange]: [
                    Constants.TimelineEntryTypes.reportingStructure,
                    Constants.TimelineEntryTypes.titleChange,
                ],
                [Constants.FeedTabs.employeeAddRemove]: [
                    Constants.TimelineEntryTypes.employeeAdd,
                    Constants.TimelineEntryTypes.employeeRemove,
                ],
            }[filterType] || Object.values(Constants.TimelineEntryTypes)
        )
    } else {
        return (
            {
                [Constants.FeedTabsWithoutJobs.all]: Object.values(Constants.TimelineEntryTypes),
                [Constants.FeedTabsWithoutJobs.posts]: [
                    Constants.TimelineEntryTypes.postText,
                    Constants.TimelineEntryTypes.postPhoto,
                    Constants.TimelineEntryTypes.postVideo,
                ],
                [Constants.FeedTabsWithoutJobs.reportingStructure]: [Constants.TimelineEntryTypes.reportingStructure],
                [Constants.FeedTabsWithoutJobs.titleChange]: [Constants.TimelineEntryTypes.titleChange],
                [Constants.FeedTabsWithoutJobs.employeeAddRemove]: [
                    Constants.TimelineEntryTypes.employeeAdd,
                    Constants.TimelineEntryTypes.employeeRemove,
                ],
            }[filterType] || Object.values(Constants.TimelineEntryTypes)
        )
    }
}
