/**
 * Created by baselqumsiyeh on 3/16/16.
 * Taken from http://www.abeautifulsite.net/detecting-mobile-devices-with-javascript/
 * Ideally we shouldn't have to depend on this too much.
 */
export const isMobileDevice = {
    Android: function () {
        return navigator.userAgent.match(/android/i)
    },
    BlackBerry: function () {
        return navigator.userAgent.match(/blackberry/i)
    },
    iOS: function () {
        return navigator.userAgent.match(/iphone|ipad|ipod/i)
    },
    Opera: function () {
        return navigator.userAgent.match(/opera mini/i)
    },
    Windows: function () {
        return navigator.userAgent.match(/iemobile/i)
    },
    any: function () {
        return (
            isMobileDevice.Android() ||
            isMobileDevice.BlackBerry() ||
            isMobileDevice.iOS() ||
            isMobileDevice.Opera() ||
            isMobileDevice.Windows()
        )
    },
}

export const whichMobileBrowser = {
    isMobileSafari: function () {
        return isMobileDevice.iOS() && navigator.userAgent.includes('Safari') && !navigator.userAgent.includes('CriOS')
    },
    isChromeForIOS: function () {
        return isMobileDevice.iOS() && navigator.userAgent.includes('CriOS')
    },
}
