import React from 'react'
import { EmploymentDuration } from '~/app/types'
import { useTranslation } from 'react-i18next'

const getDurationOranges = (years: number, months: number, prior = false) => {
    const oldTimerThreshold = 5 // # of years to categorize someone as an old timer
    const currentEmploymentOranges: JSX.Element[] = []
    const color = prior ? 'prior' : 'ow-b-c-highlight'
    for (let i = 0; i < Math.floor(years / 5); i++) {
        currentEmploymentOranges.push(<i key={`star-${i}`} className={'icon-star duration-orange ' + color} />)
    }
    for (let i = 0; i < years % 5; i++) {
        currentEmploymentOranges.push(<i key={`circle-${i}`} className={'icon-circle duration-orange ' + color} />)
    }
    if (years === 0 || (years > 0 && months >= 1 && years < oldTimerThreshold)) {
        currentEmploymentOranges.push(<i key="blank-circle" className={'icon-circle-blank duration-orange ' + color} />)
    }
    return currentEmploymentOranges
}

type StarsAndMoonsComponentProps = {
    employmentDuration: EmploymentDuration
    priorEmploymentDuration: EmploymentDuration
    priorCompanyName?: string
    isContractor?: boolean
}
export const StarsAndMoonsComponent = ({
    isContractor,
    employmentDuration,
    priorEmploymentDuration,
    priorCompanyName,
}: StarsAndMoonsComponentProps) => {
    if (isContractor) {
        return (
            <div className="duration-oranges" data-human-friendly="Contractor">
                <span title="Contractor" className="contractor-orange ow-b-c-highlight">
                    C
                </span>
            </div>
        )
    } else {
        return (
            <div className="duration-oranges-container">
                <Tenure employmentDuration={employmentDuration} />
                {Boolean(priorCompanyName) && (
                    <PriorTenure
                        priorCompanyName={priorCompanyName}
                        priorEmploymentDuration={priorEmploymentDuration}
                    />
                )}
            </div>
        )
    }
}

type TenureProps = { employmentDuration: EmploymentDuration }
const Tenure = ({ employmentDuration }: TenureProps) => {
    const duration = employmentDuration.human_friendly
    const [t] = useTranslation('common')
    return (
        <div className="duration-oranges" data-toggle="tooltip" title={t('stars_and_moons.tenure', { duration })}>
            {getDurationOranges(employmentDuration.years ?? 0, employmentDuration.months ?? 0)}
        </div>
    )
}

type PriorTenureProps = { priorEmploymentDuration: EmploymentDuration; priorCompanyName?: string }
const PriorTenure = ({ priorEmploymentDuration, priorCompanyName }: PriorTenureProps) => {
    const duration = priorEmploymentDuration.human_friendly
    const [t] = useTranslation('common')

    return (
        <div
            className="duration-oranges prior"
            data-toggle="tooltip"
            title={t('stars_and_moons.prior_tenure', {
                priorCompanyName,
                duration,
            })}
        >
            {getDurationOranges(priorEmploymentDuration.years ?? 0, priorEmploymentDuration.months ?? 0, true)}
        </div>
    )
}
