import { isMobileDevice } from '~/utils/mobileDetection'
import Handlebars from 'handlebars/lib/handlebars'
import { getAPIBaseURL } from '~/utils/urlUtils'
import { Global } from '~/global'

import * as OWUtils from '~/utils/OWUtils'
import { applyMentions } from '~/utils/stringUtils'
import * as GroupUtils from '~/utils/GroupUtils'
import i18n from 'i18next'

Handlebars.registerHelper('join', function (context, block) {
    return context.join(block.hash.delimiter)
})

Handlebars.registerHelper('linebreaksbr', function (text) {
    text = Handlebars.Utils.escapeExpression(text)
    text = text.replace(/(\r\n|\n|\r)/gm, '<br>')
    return new Handlebars.SafeString(text)
})

Handlebars.registerHelper('pluralize', function (number, singular, plural) {
    return number === 1 ? singular : plural
})

Handlebars.registerHelper('add', function (num1, num2) {
    return num1 + num2
})

Handlebars.registerHelper('likersStringListIter', function (ppl, remaining, options) {
    if (options.inverse && !ppl.length) return options.inverse(this)

    return ppl
        .map(function (item, index) {
            item.$first = index === 0
            item.$showCommaBefore = index !== 0 && ppl.length > 2
            item.$showAndBefore = ppl.length > 1 && index === ppl.length - 1 && remaining.length === 0
            return options.fn(item)
        })
        .join('')
})

Handlebars.registerHelper('ifCond', function (v1, operator, v2, options) {
    switch (operator) {
        case '==':
            return v1 === v2 ? options.fn(this) : options.inverse(this)
        case '!=':
            return v1 !== v2 ? options.fn(this) : options.inverse(this)
        case '===':
            return v1 === v2 ? options.fn(this) : options.inverse(this)
        case '==c':
            // Case insensitive compare
            return v1.toUpperCase() === v2.toUpperCase() ? options.fn(this) : options.inverse(this)
        case '<':
            return v1 < v2 ? options.fn(this) : options.inverse(this)
        case '<=':
            return v1 <= v2 ? options.fn(this) : options.inverse(this)
        case '>':
            return v1 > v2 ? options.fn(this) : options.inverse(this)
        case '>=':
            return v1 >= v2 ? options.fn(this) : options.inverse(this)
        case '&&':
            return v1 && v2 ? options.fn(this) : options.inverse(this)
        case '&!':
            return v1 && !v2 ? options.fn(this) : options.inverse(this)
        case '!&':
            return !v1 && v2 ? options.fn(this) : options.inverse(this)
        case '||':
            return v1 || v2 ? options.fn(this) : options.inverse(this)
        case '!||':
            return v1 || !v2 ? options.fn(this) : options.inverse(this)
        case '=tags=':
            return v1 === 'Skills' ? options.fn(this) : options.inverse(this)
        case 'showBothCounts':
            return v1.direct_reports_count > 0 && v1.direct_reports_count !== v1.descendants_count
                ? options.fn(this)
                : options.inverse(this)
        case 'showDirectReportsCount':
            return v1.direct_reports_count > 0 && v1.direct_reports_count === v1.descendants_count
                ? options.fn(this)
                : options.inverse(this)
        default:
            return options.inverse(this)
    }
})

Handlebars.registerHelper('ifExistsAndFalse', function (x, options) {
    return x != null && x === false ? options.fn(this) : options.inverse(this)
})
Handlebars.registerHelper('ifExistsAndTrue', function (x, options) {
    return x != null && x === true ? options.fn(this) : options.inverse(this)
})
Handlebars.registerHelper('select', function (value, options) {
    var $el = $('<select />').html(options.fn(this))
    $el.find('[value=' + value + ']').attr({ selected: 'selected' })
    return $el.html()
})

Handlebars.registerHelper('ifShowTleOptionsDropdown', function (entry, options) {
    return (entry != null && entry.new_post != null && (entry.new_post.editable || entry.new_post.deletable)) ||
        Global.IS_ACTIVE_ADMIN
        ? options.fn(this)
        : options.inverse(this)
})

Handlebars.registerHelper('shortenDate', function (passedString) {
    if (passedString === 'Never') {
        return passedString
    }

    var theString = passedString

    // only last 2 of year
    var indexOfYear = theString.lastIndexOf('/') + 1
    theString = theString.substring(0, indexOfYear) + theString.substring(indexOfYear + 2, theString.length)
    return new Handlebars.SafeString(theString)
})

Handlebars.registerHelper('convertDatetime', function (str) {
    if (str.length !== 20) {
        return str
    }

    return str.substring(5, 7) + '/' + str.substring(8, 10) + '/' + str.substring(0, 4)
})

Handlebars.registerHelper('JSONToString', function (json) {
    return JSON.stringify(json)
})

Handlebars.registerHelper('applyMentions', function (textWithMentions, isEditMode) {
    return applyMentions(textWithMentions, isEditMode, true)
})

Handlebars.registerHelper('getGroupIcon', function (group_photo_thumbnail, group_type) {
    if (group_photo_thumbnail) {
        return group_photo_thumbnail
    } else {
        return OWUtils.staticPath('groups/img/icon-list-community-lg.png')
    }
})

Handlebars.registerHelper('addStaticPrefix', function (suffix) {
    return OWUtils.staticPath(suffix)
})

Handlebars.registerHelper('getDListIcon', function (dlist) {
    return 'avatarBase' + GroupUtils.getDListIcon(dlist)
})

Handlebars.registerHelper('getTagLabel', function (plural, lowercase) {
    if (i18n) {
        var l = plural ? window.app.i18n.t('skill.label_other') : window.app.i18n.t('skill.label')
        return lowercase ? l.toLocaleLowerCase() : l
    }
})

/**
 * Helper method for registering a handlebars partial.
 * @param partialName The name of the partial (must also be the Id of the script element)
 */
function registerHandleBarPartial(partialName) {
    Handlebars.registerPartial(partialName, document.getElementById(partialName).innerHTML)
}

// Register all the of the Handlebar Partials here
if (document.getElementById('employee-spa-link')) {
    registerHandleBarPartial('employee-spa-link')
}
if (document.getElementById('community-spa-link')) {
    registerHandleBarPartial('community-spa-link')
}
if (document.getElementById('employee-search-badge-template')) {
    registerHandleBarPartial('employee-search-badge-template')
}
if (document.getElementById('employee-tag-template')) {
    registerHandleBarPartial('employee-tag-template')
}
if (document.getElementById('group-search-template')) {
    registerHandleBarPartial('group-search-template')
}
if (document.getElementById('query-search-template')) {
    registerHandleBarPartial('query-search-template')
}
if (document.getElementById('office-search-template')) {
    registerHandleBarPartial('office-search-template')
}

/**
 * A controller class that manages the functionality for showing a badge on hover of a link
 * Ideally there should only be one of instance of this class of these per page
 * Right now, this only supports showing the Employee (compact) badge when the at-mention link is hovered
 * @constructor
 */
export function HoverBadgeController() {
    // An array of the DOM Link (<a>) elements to apply this logic to.
    this.theLinkElements = null

    // The visible badge
    this.theBadge = null

    // An local cache for the data loaded from the server, for faster access
    this.dataCache = {}
    this.dataCacheMaxSize = 20

    // The amound of milliseconds to wait until the hover event is fired and data is fetched and displayed
    this.hoverDelay = 500

    // Handlebar template used to show the badge
    this.employeeBadgeTemplate = Handlebars.compile($('#employee-badge-template').html())
}

HoverBadgeController.prototype = {
    /**
     * Main method to call to set up this logic.  Should be called by outside classes
     */
    setup: function () {
        // Dont do any of this for mobile
        if (isMobileDevice.any()) {
            return
        }

        // First double check to make sure there are no visible badges in the DOM, remove them if so
        this.removeAllBadges()

        // Search the DOM for all the <a> links that need to support this hover badge
        this.getLinks()

        // Attach the hover events on the links
        this.attachHoverEventsToLinks()
    },

    /**
     * Removes all badges from the DOM, with no animation
     */
    removeAllBadges: function () {
        $('.hover-badge').remove()
    },

    /**
     * Gets all the eligible links in the DOM and stores as instance variable
     */
    getLinks: function () {
        // Initialize the arra
        this.theLinkElements = []
        var hbc = this

        // Start with all mention links on the page
        // Im not a big fan of doing this, but its the only way I could up with.
        var allMentionLinks = $('.mentionLink')

        allMentionLinks.each(function (index) {
            // For each mention Link, we need to do some extra validation
            var mentionLink = this
            if (hbc.theLinkElements.indexOf(mentionLink) !== -1) {
                return
            }
            var objectName = $(mentionLink).data('objectName')
            var lookupFieldValue = $(mentionLink).data('lookupFieldValue') // For Employees, this is the public_id

            // Only allow for employee mentions
            if (objectName === 'Employee' && lookupFieldValue) {
                // Add to our master list
                hbc.theLinkElements.push(mentionLink)
            }
        })
    },

    /**
     * Attaches hover events to the eligible links
     */
    attachHoverEventsToLinks: function () {
        var hbc = this

        // Loop through all the elements on master list
        for (var i = 0; i < this.theLinkElements.length; i++) {
            var theLinkEl = this.theLinkElements[i]
            if (theLinkEl.prepared) {
                continue
            }
            var timer = null

            // Add a "mouseenter" event
            theLinkEl.addEventListener(
                'mouseenter',
                function () {
                    var linkTarget = $(this)
                    // Set a timer to process the hover
                    timer = setTimeout(function () {
                        hbc.onHover(linkTarget)
                    }, hbc.hoverDelay)
                },
                false
            )

            // Add the "mouseleave" event
            theLinkEl.addEventListener(
                'mouseleave',
                function () {
                    var linkTarget = $(this)

                    // Stop the timer
                    clearTimeout(timer)

                    // Process the leave event
                    hbc.onLeave(linkTarget)
                },
                false
            )
            theLinkEl.prepared = true
        }
    },

    /**
     * Called when a link is hovered on
     * @param hoveredLink the hovered link aka the <a> element
     */
    onHover: function (hoveredLink) {
        var hbc = this
        // Get the lookup value so we can get the data.
        var lookupFieldValue = $(hoveredLink).data('lookupFieldValue')

        // First get the data for that employee
        hbc.getData(lookupFieldValue, function (data) {
            // When we have the data, show the badge
            hbc.showBadge(hoveredLink, data)
        })
    },

    /**
     * When the link is no longer hovered on
     * @param leftLink
     */
    onLeave: function (leftLink) {
        // Remove all hover badges from the page
        $('.hover-badge').fadeOut(350, function () {
            $(this).remove()
        })
    },

    /**
     * Gets the data for an object, needed to show the badge
     * @param lookupFieldValue The value needed to lookup an object
     * @param successCallback Called when the data lookup succeeds
     */
    getData: function (lookupFieldValue, successCallback) {
        // First check our cache to see if we already loaded this data from the server
        if (this.dataCache.hasOwnProperty(lookupFieldValue)) {
            // Get the data from the cache and call the callback
            var cachedData = this.dataCache[lookupFieldValue]
            successCallback(cachedData)
        } else {
            // Data not found in cache, so go to the server to get it
            this.requestDataFromServer(lookupFieldValue, successCallback)
        }
    },

    /**
     * Go to the server and request data for a particular lookup value
     * @param lookupFieldValue For Employees, this is the public_id
     * @param successCallback Called when the server is found
     */
    requestDataFromServer: function (lookupFieldValue, successCallback) {
        // Since we only support employee lookups on at-mention links now,
        // just do an AJAX request to get the data.
        var hbc = this
        $.ajax({
            context: this,
            url: getAPIBaseURL() + '/employees/' + lookupFieldValue + '/',
            type: 'GET',
            dataType: 'json',
            success: function (response) {
                // Add the data to our cache
                hbc.addDataToCache(lookupFieldValue, response)
                successCallback(response)
            },
            error: function (response) {
                // TODO
            },
        })
    },

    /**
     * Adds a data response from the server to the local cache.
     * The cache has a max size of |dataCacheMaxSize|
     * @param lookupFieldValue The lookup value for the data, also used as the key to the cache
     * @param data The data to cache
     */
    addDataToCache: function (lookupFieldValue, data) {
        // Get all the keys in the cache
        var keys = Object.keys(this.dataCache)
        // Check if the cache is too big
        if (keys.length === this.dataCacheMaxSize) {
            // Remove the first entry, which is the the oldest one inserted in the cache
            delete this.dataCache[keys[0]]
        }
        // Add the data to the cache
        this.dataCache[lookupFieldValue] = data
    },

    /**
     * Show the Badge in UI given the link and the data
     * @param link The link that was hovered over
     * @param data The object data
     */
    showBadge: function (link, data) {
        // Right now we only support rendering the Employee Badge
        if (link.length === 0 || data == null) {
            return
        }

        // Create the badge from the handlebar templates
        this.theBadge = $('<div>', {
            class: 'hover-badge',
            html: this.employeeBadgeTemplate({
                employee: data,
                is_compact: true,
            }),
        })

        // We need to get the position of where to place the badge
        var offset = link.offset()
        var topOffset = link.offset().top
        var widthOfLink = link[0].offsetWidth
        var widthOfBadge = 350 // Matches the width in the CSS of the hover-badge. Keep them consistent
        var left = offset.left + widthOfLink / 2 - widthOfBadge / 2
        this.theBadge.css({
            top: topOffset + 28 + 'px',
            left: left + 'px',
            position: 'absolute',
            zIndex: 50,
            display: 'none',
        })

        // Dont forgot the silly duration oranges...
        this.theBadge.find('.duration-oranges').durationOranges()

        // Add the badge to the <body>.  The positioning above makes it look lke the badge is near the link
        this.theBadge.appendTo($('body')).fadeIn(350)
    },
}
