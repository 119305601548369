import React from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

type Props = {
    timeline_moderation_is_active: boolean
    timeline_moderation_datetime: string | null
}

export const TimelineModerationBannerComponent = ({
    timeline_moderation_datetime = null,
    timeline_moderation_is_active = false,
}: Props) => {
    const { t } = useTranslation()

    return (
        <div className="navbar-banner timeline-moderation-warning">
            <div className="container-fluid">
                <p>
                    {timeline_moderation_is_active
                        ? t('banners.feed_suspended_on', {
                              date: moment(timeline_moderation_datetime),
                          })
                        : t('banners.feed_suspended_started', {
                              date: moment(timeline_moderation_datetime),
                          })}
                </p>
            </div>
        </div>
    )
}
