export const AdminUtils = {
    // Admin Page Titles - keep these in line with the ones in orgwiki/views/admin.py::admin_view
    getAdminUsersTitle() {
        return 'Manage Users | OrgWiki'
    },

    getAdminFeaturesTitle() {
        return 'Features | OrgWiki'
    },

    getAdminReportsTitle() {
        return 'Reports | OrgWiki'
    },

    getAdminCompanyTitle() {
        return 'Settings | OrgWiki'
    },
}
