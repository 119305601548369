import { Global } from '~/global'
import ReactGA from 'react-ga'
import mixpanel from 'mixpanel-browser'

export function setup() {
    const props: any = {}
    if (Global.OW_AUTH_DATA.employee) {
        props.userId = Global.OW_AUTH_DATA.employee.id
    }
    ReactGA.initialize(Global.GOOGLE_ANALYTICS_CODE, {
        gaOptions: {
            name: Global.GOOGLE_ANALYTICS_SITE_NAME,
            siteSpeedSampleRate: 10,
            sampleRate: 100,
        },
        testMode: !Global.GOOGLE_ANALYTICS_CODE,
    })
    ReactGA.ga('send', 'pageview')
    mixpanel.init(Global.MIXPANEL_API_TOKEN || '')
}
