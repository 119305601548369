import { useEffect } from 'react'
import { useLocation } from 'react-router'
import useDebounce from '~/react/common-components/hooks/useDebounce'
import { NotificationsModel } from '~/models/NotificationsModel'

const NotificationRefresher = () => {
    const location = useLocation()

    const refreshNotifications = useDebounce(() => {
        void NotificationsModel.getInstance().refreshCounts()
    }, 1000)

    useEffect(() => {
        void refreshNotifications()
    }, [refreshNotifications, location])

    return null
}

export default NotificationRefresher
