import mixpanel from 'mixpanel-browser'

import { Global } from '~/global'

const { IS_GHOST } = Global

export function mixpanelTrack(eventName, eventProperties?: any, callbackFunction?: (boolean) => void) {
    mixpanel.track(eventName, eventProperties, () => callbackFunction?.(!IS_GHOST))
    return Promise.resolve()
}

export function mixpanelIdentify(userInfo) {
    // The user id should be in user info. Get it and then delete from the user_info array
    const user_id = userInfo['user_id']
    delete userInfo['user_id']

    // Also store the last login
    userInfo['$last_login'] = new Date()

    // Scope to subdomain
    mixpanel.set_config({ cross_subdomain_cookie: true })

    // Call this to set the profile info in mixpanel.  Pass in all the user_info vars
    mixpanel.people.set(userInfo)

    // This tell mixpanel to associate all future events w/ this user
    mixpanel.identify(user_id)
}
