import './vendor'
import './legacy'

import { OWApp } from './app/OWApp'
// All stylesheets
import '../../orgwiki/static/orgwiki/scss/orgwiki.scss'
/**
 * This is where all of our JS related code should start
 * Create an OWApp and call start on it.
 * |app| is a global variable reachable from anywhere in the JS
 * @type {OWApp}
 */
const app = new OWApp()
OWApp.setInstance(app)
app.start()
