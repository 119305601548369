import { useEffect, useMemo, useRef } from 'react'
import { debounce } from '~/utils/OWUtils'

// inspiration from https://www.developerway.com/posts/debouncing-in-react
const useDebounce = <F extends (...args: any[]) => any>(callback: F, waitFor: number) => {
    // If using a debounce with a non-memoized function, the debounce can effectively turn into a delay since the
    // callback might be a new function with the next call. Rather than requiring that the callback is always
    // a memoized function, we can use a ref to the function in order to ensure that we always have the latest callback
    // and the debounce callback still points to the same function

    const ref = useRef<F>()

    useEffect(() => {
        ref.current = callback
    }, [callback])

    return useMemo(() => {
        const func = (...args) => {
            ref.current?.(...args)
        }

        return debounce(func, waitFor)
    }, [waitFor])
}

export default useDebounce
