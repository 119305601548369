import React from 'react'
import { useParams } from 'react-router'
import Bowser from 'bowser'
import { useTitle } from '~/react/common-components/hooks/useTitle'

const MagicLinkComponent = () => {
    const { token } = useParams<{ token: string }>()
    const browser = Bowser.getParser(window.navigator.userAgent)
    const isAndroid = browser.getOSName() === 'Android'

    useTitle('Mobile Sign-In | OrgWiki')

    return (
        <div className="magic-sign-in">
            <p className="title text-center">Sign In</p>

            <p className="message text-center">
                Click the Launch button <strong>from your mobile device</strong> to sign in to OrgWiki.
            </p>

            <a
                className="btn btn-primary btn-primary center-block"
                href={`ow://${isAndroid ? 'com.orgwiki.android/' : ''}mobile/magic/${token}`}
            >
                Launch OrgWiki
            </a>

            <p className="main-msg text-center">
                Having issues?{' '}
                <a href="mailto:support@theorgwiki.com" className="support-link" target="_blank">
                    Contact us
                </a>{' '}
                if you need help.
            </p>
        </div>
    )
}

export default MagicLinkComponent
