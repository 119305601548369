import { MentionableText } from '~/app/types'

export function toTitleCase(str) {
    return str.replaceAll(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase()
    })
}

function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .slice(1)
}

/**
 * Generates a GUID
 * @returns {string}
 */
export function guid() {
    return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`
}

// Use the browser's built-in functionality to quickly and safely escape the string
// From http://goo.gl/HWLKR5
export function escapeHtml(str) {
    const div = document.createElement('div')
    div.append(document.createTextNode(str))
    return div.innerHTML
}

// If you want to use a string literal in a regular expression, call this first to escape special characters
export function escapeForUseInRegex(stringForRegex) {
    return stringForRegex.replaceAll(/[$()*+./?[\\\]^{|}-]/g, String.raw`\$&`)
}

export function escapeForDataAttribute(stringForDataAttribute: string) {
    return stringForDataAttribute.replaceAll('&', '&amp;').replaceAll('"', '&quot;').replace(/</, '&lt;')
}

/**
 * Formats an external url by prepending https:// to it if needed
 * @param url
 * @returns {string}
 */
export function formatExternalUrl(url) {
    return /^http(s?):\/\/(.*)$/.test(url) ? url : 'https://' + url
}

if (String.prototype.trim === undefined) {
    String.prototype.trim = function () {
        return String(this).replaceAll(/^\s+|\s+$/g, '')
    }
}

if (String.prototype.replaceAll === undefined) {
    String.prototype.replaceAll = function (search, replacement) {
        return this.replaceAll(new RegExp(search, 'g'), replacement)
    }
}

export const applyMentions = (textWithMentions: MentionableText, isEditMode: boolean, shouldEscape?: boolean) => {
    if (!(textWithMentions && textWithMentions.text)) {
        return
    }

    let text = textWithMentions.text
    // Failsafe in case the server sends down bad data. This shouldn't happen with the logic in place on the server
    // However, if we don't account for this possibility, the timeline will break
    if (!textWithMentions.mentions) {
        return text
    }

    // Escape HTML to prevent XSS
    // NOTE: this doesnt work on CSD text with mentions for some reason.
    if (shouldEscape) {
        text = escapeHtml(text)
    }

    // {#} regex
    const placeholderMatch = new RegExp(String.raw`{\d+}`, 'g')
    let mentionCount = 0

    let formattedText = text.replaceAll(placeholderMatch, (match, index, fullString) => {
        // Since we can't do regex lookbehinds in javascript, we'll need to peek back at the previous index to look for an escape
        if (index > 0 && fullString.charAt(index - 1) == '\\') {
            return match
        }
        const mentionData = textWithMentions.mentions[mentionCount]
        // mentionData should never be null if the proper server work is done for syncing placeholders to mentions
        // However, if this throws an exception then the timeline will not render, so add a failsafe
        let convertedPlaceholder: string
        if (mentionData) {
            if (!isEditMode && 'mention_object_url' in mentionData && mentionData.mention_object_url) {
                convertedPlaceholder = `<a href='${escapeForDataAttribute(
                    mentionData.mention_object_url
                )}'                     class='mentionLink' data-object-name='${escapeForDataAttribute(
                    mentionData.mention_object_name
                )}'                     data-lookup-field-value='${escapeForDataAttribute(
                    mentionData.mention_lookup_field_value
                )}'                     data-public-id='${escapeForDataAttribute(
                    mentionData.mention_lookup_field_value
                )}'                     data-full-name='${escapeHtml(
                    escapeForDataAttribute(mentionData.mention_text_name)
                )}'>@${escapeHtml(mentionData.mention_text_name)}</a>`
            } else {
                if (isEditMode) {
                    convertedPlaceholder = '@' + escapeHtml(mentionData.mention_text_name)
                } else {
                    // If we're not in edit mode, then that means there's no url for the mention, so bold it to show that it's a mention
                    convertedPlaceholder =
                        "<strong class='mentionLink'>" + '@' + escapeHtml(mentionData.mention_text_name) + '</strong>'
                }
            }
            mentionCount++
        } else {
            convertedPlaceholder = match
        }

        return convertedPlaceholder
    })

    // Next pass we need to remove the \{ escapes
    // Looking for '\' requires a double double escape: '\\\\', meanwhile '\{' matches '{'
    const escapedBracket = new RegExp(String.raw`\\{`, 'g')
    formattedText = formattedText.replaceAll(escapedBracket, () => '{')

    return formattedText
}

/**
 * https://github.com/darkskyapp/string-hash/blob/master/index.js
 */
export const hash = (str: string) => {
    let hash = 5381,
        i = str.length

    while (i) {
        // eslint-disable-next-line unicorn/prefer-code-point
        hash = (hash * 33) ^ str.charCodeAt(--i)
    }

    /* JavaScript does bitwise operations (like XOR, above) on 32-bit signed
     * integers. Since we want the results to be always positive, convert the
     * signed int to an unsigned by doing an unsigned bitshift. */
    return hash >>> 0
}

export const removeTags = (str: string): string => {
    return str.replaceAll(/<[^>]*>?/gm, '')
}

export const decodeHTMLSymbols = (str: string): string => {
    return str.replaceAll('&lt;', '<').replaceAll('&gt;', '>').replaceAll('&nbsp;', ' ').replaceAll('&amp;', '&')
}

export const htmlToText = (str: string): string => {
    return decodeHTMLSymbols(removeTags(str))
}

export const pluralizeNoun = (noun: string, count: number, showCount: boolean) => {
    return showCount ? (count === 1 ? `1 ${noun}` : `${count} ${noun}s`) : count === 1 ? noun : `${noun}s`
}
